import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import { IonIcon } from '@ionic/react';
import {
  chatbubbleEllipses,
  chevronBack,
  chevronForward,
} from 'ionicons/icons';
import Linkify from 'linkify-react';
import { getImage } from '../utils/image';
import { timeAgo } from '../utils/time';
import PostReaction from './PostReaction';
import PostShare from './PostShare';
import { usePost } from '../hooks/usePost';
import PostReport from './PostReport';
import { IPostReply } from '../interfaces/post-reply.interface';

const PostReply = ({ postReply }: { postReply: IPostReply }) => {
  const { selectPost } = usePost();

  return (
    <div className="bg-white rounded-lg shadow-sm text-sm font-medium border1 dark:bg-dark2">
      <div className="flex gap-3 sm:p-4 p-2.5 sm:pb-2.5 text-sm font-medium">
        <Link to={`/user/${postReply.post.user.id}`}>
          <div className="w-9 h-9">
            <Avatar
              userId={postReply.post.user.id}
              username={postReply.post.user.username}
            />
          </div>
        </Link>
        <div className="flex-1">
          <Link to={`/user/${postReply.post.user.id}`}>
            <h4 className="text-black dark:text-white">
              {postReply.post.user.username}
            </h4>
          </Link>
          <div className="text-xs text-gray-500 dark:text-white/80">
            {timeAgo(new Date(postReply.post.createdAt), 'en')}
          </div>
        </div>
      </div>

      {postReply.post.content !== '' && (
        <div className="sm:px-4 p-2.5 pb-0 pt-2.5">
          <p
            onClick={() => {
              selectPost(postReply.post.id);
            }}
            className="font-normal cursor-pointer whitespace-pre-line"
          >
            <Linkify
              options={{
                className: 'text-[#db00ff]',
                target: '_blank',
              }}
            >
              {postReply.post.content}
            </Linkify>
          </p>
        </div>
      )}

      {postReply.post?.hashtags?.length > 0 && (
        <div className="sm:px-4 p-2.5 pb-0">
          <p className="font-normal">
            {postReply.post?.hashtags?.map((hashtag, idx) => (
              <span key={idx} className="mr-2 text-[#db00ff]">
                #{hashtag.id}
              </span>
            ))}
          </p>
        </div>
      )}

      {postReply.post?.attachments?.length > 0 && (
        <div
          className="relative uk-visible-toggle sm:px-4 p-2.5 pb-0"
          tabIndex={-1}
          data-uk-slideshow="animation: push;ratio: 4:3"
        >
          <ul className="uk-slideshow-items overflow-hidden rounded-xl">
            {postReply.post.attachments.map((attachment, idx) => (
              <li key={idx} className="w-full">
                <img
                  onClick={() => {
                    selectPost(postReply.post.id);
                  }}
                  src={getImage(attachment.path)}
                  alt=""
                  className="w-full h-full absolute object-cover insta-0"
                />
              </li>
            ))}
          </ul>

          <a
            className="nav-prev left-6"
            href="#"
            data-uk-slideshow-item="previous"
          >
            <IonIcon icon={chevronBack} className="text-2xl" />
          </a>
          <a
            className="nav-next right-6"
            href="#"
            data-uk-slideshow-item="next"
          >
            <IonIcon icon={chevronForward} className="text-2xl" />
          </a>
        </div>
      )}

      <div className="sm:p-4 p-2.5 flex items-center gap-4 text-xs font-semibold">
        <div>
          <div className="flex items-center gap-2.5">
            <PostReaction post={postReply.post} />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="button-icon bg-slate-200/70 dark:bg-slate-700 cursor-pointer"
            onClick={() => {
              selectPost(postReply.post.id);
            }}
          >
            <IonIcon icon={chatbubbleEllipses} className="text-xl" />
          </button>

          <span>{postReply.post.commentCount}</span>
        </div>

        <PostShare post={postReply.post} iconSize="xl" />

        <PostReport
          post={postReply.post}
          iconSize="xl"
          classNames="button-icon"
        />
      </div>

      <div className="sm:p-4 p-2.5 border-t border-gray-100 font-normal space-y-3 relative dark:border-slate-700/40">
        <div className="flex items-start gap-3 relative">
          <Link to={`/user/${postReply.user.id}`}>
            <div className="w-6 h-6 mt-1">
              <Avatar
                userId={postReply.user.id}
                username={postReply.user.username}
              />
            </div>
          </Link>
          <div className="flex-1">
            <Link
              to={`/user/${postReply.user.id}`}
              className="text-black font-medium inline-block dark:text-white"
            >
              {postReply.user.username}
            </Link>
            <p className="mt-0.5">
              <Linkify
                options={{
                  className: 'text-[#db00ff]',
                  target: '_blank',
                }}
              >
                {postReply.comment}
              </Linkify>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostReply;
