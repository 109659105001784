import React from 'react';
import NoBackground from '../assets/images/no-background.png';
import Avatar from './Avatar';
import { IonIcon } from '@ionic/react';
import {
  addCircleOutline,
  chatbubbleOutline,
  ellipsisHorizontal,
  flagOutline,
  personAddOutline,
  removeCircleOutline,
  stopCircleOutline,
} from 'ionicons/icons';
import { IUser } from '../interfaces/user.interface';
import { Link } from 'react-router-dom';
import AddFriendRequestModal from './AddFriendRequestModal';
import ReportUserModal from './ReportUserModal';
import BlockUserModal from './BlockUserModal';
import FollowUser from './FollowUser';
import UnfollowUser from './UnfollowUser';

const UserHeader = ({
  profileUser,
  isMe,
  isFriend,
  activeTab,
}: {
  profileUser: IUser;
  isMe: boolean;
  isFriend: boolean;
  activeTab: 'posts' | 'replies' | 'games';
}) => {
  return (
    <>
      <div className="bg-white shadow rounded-lg dark:bg-dark2">
        <div className="relative overflow-hidden w-full lg:h-44 h-32">
          <img
            src={NoBackground}
            alt="Profile Background"
            className="h-full w-full object-cover inset-0"
          />
        </div>

        <div className="p-3">
          <div className="flex flex-col justify-center md:items-center lg:-mt-40 -mt-24">
            <div className="relative lg:h-40 lg:w-40 w-24 h-24 mb-4 z-10 rounded-full dark:bg-dark3 bg-secondery">
              <Avatar userId={profileUser.id} username={profileUser.username} />
            </div>

            <h3 className="md:text-3xl text-base font-bold text-black dark:text-white">
              {profileUser.username}
            </h3>

            <p className="mt-2 text-sm md:text-base text-gray-700 dark:text-white/80">
              Followers - <b>{profileUser.followersCount}</b>, Following -{' '}
              <b>{profileUser.followeesCount}</b>, Posts -{' '}
              <b>{profileUser.postsCount}</b>, Games -{' '}
              <b>{profileUser.gamesCount}</b>
            </p>

            <p className="mt-2 max-w-xl text-sm md:font-normal font-light text-center">
              {profileUser.bio}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between mt-3 border-t border-gray-100 px-2 max-lg:flex-col dark:border-slate-700">
          {!isMe && (
            <div className="flex items-center gap-2 text-sm py-2 pr-1 max-md:w-full lg:order-2">
              {profileUser.isFriend ? (
                <Link
                  to={`/chats?userId=${profileUser.id}`}
                  className="button dark:bg-dark3 flex items-center gap-2 py-1.5 px-3.5 max-md:flex-1 rounded-lg bg-secondery"
                >
                  <IonIcon icon={chatbubbleOutline} className="text-xl" />
                  <span className="text-sm">Message</span>
                </Link>
              ) : (
                <>
                  {!profileUser.isFollowed ? (
                    <FollowUser
                      followee={profileUser}
                      type={'button'}
                      className={
                        'button dark:bg-dark3 flex items-center gap-2 py-1.5 px-3.5 max-md:flex-1 rounded-lg bg-secondery'
                      }
                    >
                      <IonIcon icon={addCircleOutline} className="text-xl" />
                      <span className="text-sm">Follow</span>
                    </FollowUser>
                  ) : (
                    <UnfollowUser
                      followee={profileUser}
                      type={'button'}
                      className={
                        'button dark:bg-dark3 flex items-center gap-2 py-1.5 px-3.5 max-md:flex-1 rounded-lg bg-secondery'
                      }
                    >
                      <IonIcon icon={removeCircleOutline} className="text-xl" />
                      <span className="text-sm">Unfollow</span>
                    </UnfollowUser>
                  )}
                </>
              )}

              <div>
                <button
                  type="submit"
                  className="rounded-lg bg-secondery flex px-2.5 py-2 dark:bg-dark3"
                >
                  <IonIcon icon={ellipsisHorizontal} className="xl" />
                </button>
                <div
                  className="w-[240px] uk-dropdown-close"
                  data-uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click;offset:10"
                >
                  <nav>
                    {!isFriend && profileUser.isBefriendable && (
                      <a
                        className="cursor-pointer"
                        data-uk-toggle={`target: #add-friend-request-${profileUser.id}`}
                      >
                        <IonIcon icon={personAddOutline} className="text-xl" />
                        Add as friend
                      </a>
                    )}

                    {profileUser.isFriend && (
                      <>
                        {profileUser.isFollowed ? (
                          <UnfollowUser
                            followee={profileUser}
                            type={'a'}
                            className={'cursor-pointer'}
                          >
                            <IonIcon
                              icon={removeCircleOutline}
                              className="text-xl"
                            />
                            <>Unfollow</>
                          </UnfollowUser>
                        ) : (
                          <FollowUser
                            followee={profileUser}
                            type={'a'}
                            className={'cursor-pointer'}
                          >
                            <IonIcon
                              icon={addCircleOutline}
                              className="text-xl"
                            />
                            <>Follow</>
                          </FollowUser>
                        )}
                      </>
                    )}
                    <a
                      className="cursor-pointer"
                      data-uk-toggle={`target: #report-user-${profileUser.id}`}
                    >
                      <IonIcon icon={flagOutline} className="text-xl" />
                      Report
                    </a>
                    <hr />
                    <a
                      className="cursor-pointer text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                      data-uk-toggle={`target: #block-user-${profileUser.id}`}
                    >
                      <IonIcon icon={stopCircleOutline} className="text-xl" />
                      Block
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          )}

          <nav className="flex gap-0.5 rounded-lg -mb-px text-gray-600 font-medium text-[15px] dark:text-white max-md:w-full max-md:overflow-x-auto">
            <Link
              to={`/user/${profileUser.id}`}
              className={`inline-block py-3 leading-8 px-3.5 ${activeTab === 'posts' ? 'border-b-2 border-[#db00ff] text-[#db00ff]' : ''}`}
            >
              Posts
            </Link>
            <Link
              to={`/user/${profileUser.id}/replies`}
              className={`inline-block py-3 leading-8 px-3.5 ${activeTab === 'replies' ? 'border-b-2 border-[#db00ff] text-[#db00ff]' : ''}`}
            >
              Replies
            </Link>
            <Link
              to={`/user/${profileUser.id}/games`}
              className={`inline-block py-3 leading-8 px-3.5 ${activeTab === 'games' ? 'border-b-2 border-[#db00ff] text-[#db00ff]' : ''}`}
            >
              Games
            </Link>
          </nav>
        </div>
      </div>

      {!isMe && (
        <>
          <AddFriendRequestModal
            user={profileUser}
            targetId={`add-friend-request-${profileUser.id}`}
          />

          <ReportUserModal
            userId={profileUser.id}
            targetId={`report-user-${profileUser.id}`}
          />

          <BlockUserModal
            userId={profileUser.id}
            targetId={`block-user-${profileUser.id}`}
          />
        </>
      )}
    </>
  );
};

export default UserHeader;
