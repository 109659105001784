import Api from '../utils/api';
import { IFriendship } from '../interfaces/friendship.interface';
import { IUser } from '../interfaces/user.interface';

export const FriendshipService = {
  getReceivedFriendRequests: async function (): Promise<IFriendship[]> {
    const { data } = await Api.get<IFriendship[]>(
      '/friendship/requests/received',
    );

    return data.map((item) => {
      return { ...item, ...{ type: 'RECEIVED' } };
    });
  },
  getRequestedFriendRequests: async function (): Promise<IFriendship[]> {
    const { data } = await Api.get<IFriendship[]>(
      '/friendship/requests/requested',
    );

    return data.map((item) => {
      return { ...item, ...{ type: 'REQUESTED' } };
    });
  },
  getAcceptedFriendRequests: async function ({
    pageParam = 0,
  }): Promise<{ friendships: IFriendship[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/friendship/requests/accepted?offset=${pageParam}`;

    const { data } = await Api.get<IFriendship[]>(url);

    return { friendships: data, offset: pageParam };
  },
  requestFriendRequest: async function (toUserId: string): Promise<void> {
    await Api.post<IUser[]>('friendship/request', { userId: toUserId });
  },
  rejectFriendRequest: async function (friendshipId: string): Promise<void> {
    await Api.post(`/friendship/request/${friendshipId}/reject`);
  },
  acceptFriendRequest: async function (friendshipId: string): Promise<void> {
    await Api.post(`/friendship/request/${friendshipId}/accept`);
  },
  removeFriendRequest: async function (friendshipId: string): Promise<void> {
    await Api.post(`/friendship/request/${friendshipId}/delete`);
  },
  getRecentlyActiveFriends: async function (): Promise<IFriendship[]> {
    const { data } = await Api.get<IFriendship[]>(
      `/friendship/recently-active`,
    );

    return data;
  },
};
