import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({
  children,
  classes,
}: {
  children: string | JSX.Element | JSX.Element[];
  classes?: string;
}) => {
  return (
    <div id="wrapper">
      <Header />

      <Sidebar />

      <main
        id="site__main"
        className={`${classes ? classes : '2xl:ml-[--w-side] xl:ml-[--w-side-sm] px-5 py-5 sm:py-10 h-[calc(100vh-var(--m-top))] mt-[--m-top]'}`}
        //className={`${classes ? classes : 'px-5 py-5 sm:py-10 mt-[--m-top]'}`}
      >
        {children}
      </main>
    </div>
  );
};

export default Layout;
