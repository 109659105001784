import Api from '../utils/api';
import { IPost } from '../interfaces/post.interface';

export const TimelineService = {
  getUserTimeline: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ posts: IPost[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/timeline/${queryKey[1]}?offset=${pageParam}`;

    const { data } = await Api.get<IPost[]>(url);

    return { posts: data, offset: pageParam };
  },
};
