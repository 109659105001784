import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserFollowService } from '../services/UserFollowService';
import InfoToast from './InfoToast';
import ErrorToast from './ErrorToast';
import { IUser } from '../interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';

const UnfollowUser = ({
  followee,
  children,
  type,
  className,
}: {
  followee: IUser;
  children: string | JSX.Element | JSX.Element[];
  type: 'a' | 'button';
  className?: string;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const unfollowUserMutation = useMutation(
    () => {
      return UserFollowService.unfollowUser(followee.id);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['userProfile', followee.id]);
        void queryClient.invalidateQueries(['userFollowing']);
        void queryClient.invalidateQueries(['similarUsers']);
        void queryClient.invalidateQueries(['exploreFeed']);
        void queryClient.invalidateQueries(['followingFeed']);

        InfoToast(
          t('FollowUser.successfullyUnfollowed', {
            username: followee.username,
          }),
        );
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  if (type === 'button') {
    return (
      <>
        {unfollowUserMutation.isLoading && <Loader />}
        <button
          className={className}
          onClick={() => unfollowUserMutation.mutate()}
        >
          {children}
        </button>
      </>
    );
  } else {
    return (
      <>
        {unfollowUserMutation.isLoading && <Loader />}

        <a className={className} onClick={() => unfollowUserMutation.mutate()}>
          {children}
        </a>
      </>
    );
  }
};

export default UnfollowUser;
