import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import { useTranslation } from 'react-i18next';
import { UserService } from '../services/UserService';
import Loader from '../components/Loader';
import { useAuthStore } from '../store/zustand';
import UserHeader from '../components/UserHeader';
import UserSidebar from '../components/UserSidebar';
import UserGame from '../components/UserGame';
import NotFoundError from '../components/NotFoundError';
import GeneralError from '../components/GeneralError';
import CreatePostModal from '../components/CreatePostModal';
import { IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';

const UserGames = () => {
  const { t } = useTranslation();

  const currentUser = useAuthStore((state) => state.user);

  const { userId } = useParams();

  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isFriend, setIsFriend] = useState(false);

  const getUserQuery = useQuery({
    queryKey: ['userProfile', userId],
    queryFn: async () => {
      return await UserService.getUser(userId as string);
    },
    onError: async (e: any) => {
      if (e?.response?.status === 404) {
        setNotFound(true);
      } else {
        setError(true);
      }
    },
  });

  const getUserGamesQuery = useInfiniteQuery(
    ['userGames', userId],
    // @ts-ignore
    UserService.getUserGames,
    {
      getNextPageParam: (result) =>
        result?.userGames?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  useEffect(() => {
    if (getUserQuery?.data && currentUser) {
      setIsFriend(
        getUserQuery.data?.isFriend !== undefined
          ? getUserQuery.data?.isFriend
          : false,
      );
    }
  }, [currentUser, getUserQuery]);

  return (
    <>
      {error || notFound ? (
        <>{error ? <GeneralError /> : <NotFoundError />}</>
      ) : (
        <Layout>
          <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
            {(getUserQuery.isLoading ||
              getUserGamesQuery.isLoading ||
              currentUser === undefined) && <Loader />}

            {getUserQuery.data && currentUser && (
              <>
                <UserHeader
                  profileUser={getUserQuery.data}
                  activeTab={'games'}
                  isMe={currentUser.id === userId}
                  isFriend={isFriend}
                />

                <div
                  className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
                  id="js-oversized"
                >
                  <div className="flex-1 lg:order-1 order-2">
                    {getUserGamesQuery?.data &&
                      getUserGamesQuery.data?.pages?.length !== 0 && (
                        <InfiniteScroll
                          dataLength={
                            getUserGamesQuery?.data
                              ? getUserGamesQuery?.data?.pages?.flatMap(
                                  (page) => page.userGames,
                                )?.length || 0
                              : 0
                          }
                          next={getUserGamesQuery.fetchNextPage}
                          hasMore={getUserGamesQuery.hasNextPage || false}
                          loader={null}
                        >
                          <div className="grid sm:grid-cols-3 md:grid-cols-4 grid-cols-2 gap-3">
                            {getUserGamesQuery?.data?.pages?.map((group, i) => (
                              <React.Fragment key={i}>
                                {group.userGames.map((userGame, idx) => (
                                  <UserGame key={idx} userGame={userGame} />
                                ))}
                              </React.Fragment>
                            ))}
                          </div>
                        </InfiniteScroll>
                      )}
                  </div>

                  <div className="lg:w-[400px] lg:order-2 order-1">
                    <UserSidebar profileUser={getUserQuery.data} />
                  </div>
                </div>

                {currentUser?.id === userId && (
                  <>
                    <CreatePostModal targetId={`create-post`} />

                    <div>
                      <button
                        type="button"
                        className="sm:m-10 m-5 px-4 py-2.5 rounded-2xl bg-gradient-to-tr from-[#e232ff] to-[#db00ff] text-white shadow fixed bottom-0 right-0 group flex items-center gap-1"
                        data-uk-toggle={`target: #create-post`}
                      >
                        <IonIcon icon={addCircleOutline} className="text-2xl" />

                        <div className="text-base font-semibold">Post</div>
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Layout>
      )}
    </>
  );
};

export default UserGames;
