import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Login from './routes/Login';
import PublicRoute from './routes/PublicRoute';
import Register from './routes/Register';
import { useThemeMode } from './hooks/useThemeMode';
import { Toaster } from 'react-hot-toast';
import ForgotPassword from './routes/ForgotPassword';
import ResendVerification from './routes/ResendVerification';
import './i18n/config';
import Logout from './routes/Logout';
import AccountVerification from './routes/AccountVerification';
import ProtectedRoute from './routes/ProtectedRoute';
import Account from './routes/Account';
import ResetPassword from './routes/ResetPassword';
import PartnerProgram from './routes/PartnerProgram';
import UserPosts from './routes/UserPosts';
import UserGames from './routes/UserGames';
import AccountGames from './routes/AccountGames';
import AccountOrders from './routes/AccountOrders';
import Feed from './routes/Feed';
import AccountFriends from './routes/AccountFriends';
import AccountPurchases from './routes/AccountPurchases';
import AccountWaypoints from './routes/AccountWaypoints';
import WaypointSearch from './routes/WaypointSearch';
import UserSearch from './routes/UserSearch';
import Chats from './routes/Chats';
import Waypoint from './routes/Waypoint';
import UserReplies from './routes/UserReplies';

const App = () => {
  useThemeMode();

  return (
    <>
      <Helmet>
        <link href="/css/style.css" rel="stylesheet" />

        <script src="/js/uikit.min.js" type="text/javascript" />
        <script src="/js/simplebar.js" type="text/javascript" />

        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route element={<Login />} path="/login" />
            <Route element={<Register />} path="/register" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route
              element={<AccountVerification />}
              path="/account-verification"
            />
            <Route
              element={<ResendVerification />}
              path="/resend-verification"
            />
            <Route element={<ResetPassword />} path="/reset-password" />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Feed />} path="/" />
            <Route element={<Account />} path="/account" />
            <Route element={<AccountGames />} path="/account/game-library" />
            <Route element={<AccountFriends />} path="/account/friends" />
            <Route element={<AccountWaypoints />} path="/account/waypoints" />
            <Route element={<AccountOrders />} path="/account/orders" />
            <Route element={<AccountPurchases />} path="/account/purchases" />
            <Route element={<PartnerProgram />} path="/partner-program" />
            <Route element={<UserSearch />} path="/user/search" />
            <Route element={<WaypointSearch />} path="/waypoint/search" />
            <Route element={<UserPosts />} path="/user/:userId" />
            <Route element={<UserReplies />} path="/user/:userId/replies" />
            <Route element={<UserGames />} path="/user/:userId/games" />
            <Route element={<Chats />} path="/chats" />
            <Route
              element={<Waypoint />}
              path="/waypoint/:waypointId/:waypointName"
            />
          </Route>
          <Route element={<Logout />} path="/logout" />

          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>

      <Toaster position={'top-right'} />
    </>
  );
};

export default App;
