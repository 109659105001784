import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IonIcon } from '@ionic/react';
import { notificationsOutline } from 'ionicons/icons';
import { NotificationType } from '../enums/notification-type.enum';
import Avatar from './Avatar';
import { NotificationService } from '../services/NotificationService';
import { INotification } from '../interfaces/notification.interface';
import ErrorToast from './ErrorToast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IUserFollowFollowedNotification } from '../interfaces/user-follow-followed-notification.interface';
import { useAuthStore } from '../store/zustand';
import { IPostReactionLikedNotification } from '../interfaces/post-reaction-liked-notification.interface';
import { IFriendshipRequestAcceptedNotification } from '../interfaces/friendship-request-accepted-notification.interface';

const HeaderNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user = useAuthStore((state) => state.user);

  const getUserNotificationsQuery = useQuery({
    queryKey: ['userNotifications'],
    queryFn: async () => {
      return await NotificationService.getNotifications();
    },
    refetchOnMount: false,
    refetchInterval: 120000,
  });

  const markNotificationAsReadMutation = useMutation(
    (notificationId: string) => {
      return NotificationService.markNotificationAsRead(notificationId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['userNotifications']);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const renderNotification = (notification: INotification) => {
    switch (notification.type) {
      case NotificationType['Friendship.Received']:
        return (
          <>
            <b className="font-bold">{notification.data.fromUser.username}</b>{' '}
            sent you a friend request
          </>
        );
      case NotificationType['Friendship.Accepted']:
        return (
          <>
            <b className="font-bold">{notification.data.fromUser.username}</b>{' '}
            accepted your friend request
          </>
        );
      case NotificationType['UserFollow.Followed']:
        return (
          <>
            <b className="font-bold">{notification.data.fromUser.username}</b>{' '}
            started following you
          </>
        );
      case NotificationType['PostReaction.Liked']:
        return (
          <>
            <b className="font-bold">{notification.data.fromUser.username}</b>{' '}
            liked one of your posts
          </>
        );
      case NotificationType['PostComment.New']:
        return (
          <>
            <b className="font-bold">{notification.data.fromUser.username}</b>{' '}
            commented on one of your posts
          </>
        );
      default:
        return '';
    }
  };

  const renderAvatar = (notification: INotification) => {
    switch (notification.type) {
      case NotificationType['Friendship.Received']:
        return (
          <Link to={`/user/${notification.data.fromUser.id}`}>
            <Avatar
              userId={notification.data.fromUser.id}
              username={notification.data.fromUser.username}
            />
          </Link>
        );
      case NotificationType['Friendship.Accepted']:
        return (
          <Link to={`/user/${notification.data.fromUser.id}`}>
            <Avatar
              userId={notification.data.fromUser.id}
              username={notification.data.fromUser.username}
            />
          </Link>
        );
      case NotificationType['UserFollow.Followed']:
        return (
          <Link to={`/user/${notification.data.fromUser.id}`}>
            <Avatar
              userId={notification.data.fromUser.id}
              username={notification.data.fromUser.username}
            />
          </Link>
        );
      case NotificationType['PostReaction.Liked']:
        return (
          <Link to={`/user/${notification.data.fromUser.id}`}>
            <Avatar
              userId={notification.data.fromUser.id}
              username={notification.data.fromUser.username}
            />
          </Link>
        );
      case NotificationType['PostComment.New']:
        return (
          <Link to={`/user/${notification.data.fromUser.id}`}>
            <Avatar
              userId={notification.data.fromUser.id}
              username={notification.data.fromUser.username}
            />
          </Link>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <button
        type="button"
        className="py-0.5 px-1 sm:w-9 sm:h-9 w-7 h-7 relative rounded-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400 dark:text-white"
      >
        {getUserNotificationsQuery?.data &&
          getUserNotificationsQuery?.data?.filter(
            (notification) => !notification.read,
          )?.length > 0 && (
            <div className="absolute top-0 right-0 -m-1 bg-red-600 text-white text-xs w-4 h-4 rounded-full">
              {getUserNotificationsQuery?.data?.filter(
                (notification) => !notification.read,
              )?.length < 10 &&
                getUserNotificationsQuery?.data?.filter(
                  (notification) => !notification.read,
                )?.length}
            </div>
          )}
        <IonIcon icon={notificationsOutline} className="text-xl align-middle" />
      </button>

      {getUserNotificationsQuery?.data && (
        <div
          className="hidden bg-white rounded-lg pr-1.5 drop-shadow-xl dark:bg-slate-800 border2 absolute z-50 [.uk-open]:block"
          data-uk-drop="offset:6;pos: bottom-right; animate-out: true; animation: uk-animation-scale-up uk-transform-origin-top-right "
        >
          <div className="flex items-center justify-between gap-2 p-4 pb-2">
            <h3 className="font-bold text-xl"> Notifications </h3>
          </div>

          {getUserNotificationsQuery.data?.length === 0 && (
            <div className="flex items-center justify-center h-full mb-2 dark:text-white">
              <p className="text-sm text-center text-gray-500">
                No new notifications
              </p>
            </div>
          )}
          <div
            className={`text-sm w-full overflow-y-auto pr-2 scrollbar-thin scrollbar-track-white scrollbar-thumb-[#E9EAED] dark:scrollbar-thumb-bgbody dark:scrollbar-track-slate-800 ${getUserNotificationsQuery.data?.length !== 0 ? 'h-[400px]' : ''}`}
          >
            <div className="pl-2 p-1 text-sm font-normal dark:text-white">
              {getUserNotificationsQuery?.data?.map((notification, ixd) => {
                return (
                  <div
                    key={ixd}
                    className="relative flex items-center gap-3 p-2 duration-200 rounded-xl pr-10 hover:bg-secondery dark:hover:bg-white/10 cursor-pointer"
                    onClick={() => {
                      if (!notification.read) {
                        markNotificationAsReadMutation.mutate(notification.id);
                      }

                      switch (notification.type) {
                        case NotificationType['Friendship.Received']:
                          navigate('/account/friends');
                          break;
                        case NotificationType['Friendship.Accepted']:
                          navigate(
                            `/user/${(notification.data as IFriendshipRequestAcceptedNotification).fromUser.id}`,
                          );
                          break;
                        case NotificationType['UserFollow.Followed']:
                          navigate(
                            `/user/${(notification.data as IUserFollowFollowedNotification).fromUser.id}`,
                          );
                          break;
                        case NotificationType['PostReaction.Liked']:
                          navigate(
                            `/user/${user?.id}?post=${(notification.data as IPostReactionLikedNotification).post.id}`,
                          );
                          break;
                        case NotificationType['PostComment.New']:
                          navigate(
                            `/user/${user?.id}?post=${(notification.data as IPostReactionLikedNotification).post.id}`,
                          );
                          break;
                      }
                    }}
                  >
                    <div className="relative w-12 h-12 shrink-0">
                      {renderAvatar(notification)}
                    </div>
                    <div className="flex-1">
                      {renderNotification(notification)}

                      <div className="text-xs text-gray-500 mt-1.5 dark:text-white/80">
                        {(() => {
                          const hoursAgo = Math.round(
                            Math.abs(
                              new Date().getTime() -
                                new Date(notification.date).getTime(),
                            ) / 3600000,
                          );

                          if (hoursAgo >= 24) {
                            return (
                              <>
                                {new Date(
                                  notification.date,
                                ).toLocaleDateString()}
                              </>
                            );
                          } else if (hoursAgo === 0) {
                            return <>just now</>;
                          } else {
                            return (
                              <>
                                {hoursAgo} {hoursAgo === 1 ? 'hour' : 'hours'}{' '}
                                ago
                              </>
                            );
                          }
                        })()}
                      </div>
                      {!notification.read && (
                        <div className="w-2.5 h-2.5 bg-[#db00ff] rounded-full absolute right-3 top-5"></div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderNotifications;
