import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { Trans, useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { OrderService } from '../services/OrderService';
import { LanguageCode } from '../enums/language-code.enum';
import { getImage } from '../utils/image';
import { useAuthStore } from '../store/zustand';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RingLoader } from 'react-spinners';
import { IStandardGameKey } from '../interfaces/standard-game-key.interface';
import { StandardKeyDeliveryStatus } from '../enums/standard-key-delivery-status.enum';
import { Link } from 'react-router-dom';

const AccountPurchases = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const user = useAuthStore((state) => state.user);

  const getPurchasedProducts = useInfiniteQuery(
    ['gameKeys', LanguageCode.EN],
    // @ts-ignore
    OrderService.getStandardUserGameKeys,
    {
      getNextPageParam: (result) =>
        result?.gameKeys?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const revealStandardGameKeyMutation = useMutation(
    ({ standardGameKey }: { standardGameKey: IStandardGameKey }) => {
      return OrderService.revealStandardUserGameKey(
        standardGameKey.id,
        LanguageCode.EN,
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['gameKeys', LanguageCode.EN]);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(user === undefined || getPurchasedProducts.isLoading) && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>
            <div className="flex-1">
              <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                <div>
                  <h2 className="md:text-lg text-base font-semibold text-black">
                    Purchased Games
                  </h2>
                  <p className="font-normal text-sm text-gray-500 leading-6">
                    The games that you have purchased.
                  </p>
                </div>
              </div>

              <InfiniteScroll
                dataLength={
                  getPurchasedProducts?.data
                    ? getPurchasedProducts?.data?.pages?.flatMap(
                        (page) => page.gameKeys,
                      )?.length || 0
                    : 0
                }
                next={getPurchasedProducts.fetchNextPage}
                hasMore={getPurchasedProducts.hasNextPage || false}
                loader={<div className="my-10">Loading more...</div>}
              >
                <div className="grid sm:grid-cols-5 md:grid-cols-6 xl:grid-cols-7 grid-cols-2 gap-3">
                  {getPurchasedProducts?.data?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                      {group.gameKeys.map((gameKey, idx) => (
                        <div key={idx} className="card">
                          <div className="card-media aspect-auto">
                            <img
                              className="!relative object-cover"
                              src={getImage(
                                gameKey.product?.coverImage,
                                'packshot',
                              )}
                              alt={`${gameKey.product.name} Cover`}
                            />
                          </div>
                          <div className="card-body">
                            <h4 className="card-title line-clamp-1">
                              {gameKey.product.name}
                            </h4>
                            <p className="card-text line-clamp-1">
                              {gameKey.product.platforms
                                .map((platform) =>
                                  // @ts-ignore
                                  t(`Platforms.${platform}`),
                                )
                                .join(', ')}
                            </p>
                            {gameKey.deliveryStatus ===
                            StandardKeyDeliveryStatus.DELIVERED ? (
                              <button
                                className="button rounded-lg py-1.5 px-4 bg-secondery"
                                data-uk-toggle={`target: #reveal-key-${gameKey.id}`}
                                onClick={() => {
                                  revealStandardGameKeyMutation.reset();

                                  if (gameKey.revealed) {
                                    revealStandardGameKeyMutation.mutate({
                                      standardGameKey: gameKey,
                                    });
                                  }
                                }}
                              >
                                {gameKey.revealed
                                  ? t('PurchasedProducts.showKeys')
                                  : t('PurchasedProducts.revealKeys')}
                              </button>
                            ) : (
                              <button
                                disabled
                                className="button py-1 px-2 sm:py-1.5 sm:px-4 dark:text-slate-50 text-slate-800 cursor-default"
                              >
                                {t(`DeliveryStatus.${gameKey.deliveryStatus}`)}
                              </button>
                            )}
                          </div>

                          <div id="reveal-key-modal-wrapper">
                            <div
                              className="hidden lg:p-20 uk- open"
                              id={`reveal-key-${gameKey.id}`}
                              data-uk-modal="container: #reveal-key-modal-wrapper;"
                            >
                              <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
                                <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
                                  <div className="text-base font-semibold text-black dark:text-white px-10">
                                    {gameKey?.product.name}
                                  </div>
                                  <button
                                    type="button"
                                    className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                {revealStandardGameKeyMutation.isLoading ? (
                                  <RingLoader
                                    color={'#db00ff'}
                                    size={70}
                                    speedMultiplier={0.8}
                                    className="mx-auto my-5"
                                  />
                                ) : (
                                  <>
                                    {revealStandardGameKeyMutation.data ? (
                                      <div className="p-5 text-sm">
                                        {revealStandardGameKeyMutation.data.map(
                                          (revealedGameKey, index) => {
                                            return (
                                              <div
                                                className="mb-4 last:mb-0"
                                                key={index}
                                              >
                                                <div className="text-sm text-center">
                                                  {revealedGameKey.name}
                                                </div>
                                                {revealedGameKey?.keyProvider
                                                  ?.toLowerCase()
                                                  ?.includes('steam') ? (
                                                  <div className="flex mt-2 gap-3">
                                                    <div className="flex-1 text-sm font-semibold text-center bg-slate-50 dark:bg-slate-900 text-neutral-6000 dark:text-white rounded-lg p-2.5 pl-5">
                                                      {revealedGameKey.key}
                                                    </div>
                                                    <Link
                                                      target="_blank"
                                                      to={`https://store.steampowered.com/account/registerkey?key=${revealedGameKey.key}`}
                                                      className="flex button lg:px-7 text-sm rounded-lg bg-secondery"
                                                    >
                                                      Activate
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  <div className="mt-2 text-sm font-semibold text-center bg-slate-50 dark:bg-slate-900 text-neutral-6000 dark:text-white rounded-lg p-2.5 pl-5">
                                                    {revealedGameKey.key}
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          },
                                        )}
                                        {gameKey?.product?.instructions && (
                                          <div className="mb-4">
                                            <div className="text-sm text-center">
                                              Instructions
                                            </div>
                                            <div className="mt-2 text-sm bg-slate-50 dark:bg-slate-900 text-neutral-6000 dark:text-white rounded-lg p-2.5 pl-5">
                                              {gameKey?.product?.instructions}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        <div className="p-5 text-sm">
                                          <p className="mb-1 font-bold">
                                            {t('PurchasedProducts.revealKeys')}
                                          </p>

                                          <Trans i18nKey="PurchasedProducts.revealKeysQuestion" />
                                        </div>
                                        <div className="px-5 pb-5 flex justify-between items-center">
                                          <div className="flex items-center gap-3">
                                            <button
                                              type="button"
                                              className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800"
                                              onClick={() => {
                                                void revealStandardGameKeyMutation.mutate(
                                                  {
                                                    standardGameKey: gameKey,
                                                  },
                                                );
                                              }}
                                            >
                                              Yes
                                            </button>
                                            <button
                                              type="button"
                                              className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                                            >
                                              No
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AccountPurchases;
