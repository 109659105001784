import React, { useState } from 'react';

const WaypointsSidebar = () => {
  const [
    expandReceivedJoinWaypointRequests,
    setExpandReceivedJoinWaypointRequests,
  ] = useState(false);

  const [expandSentJoinWaypointRequests, setExpandSentJoinWaypointRequests] =
    useState(false);

  const receivedJoinWaypointRequestsToShow = 5;

  const sentJoinWaypointRequestsToShow = 5;

  const receivedJoinWaypointRequests = [];
  const sentJoinWaypointRequests = [];

  return (
    <div
      className="flex lg:pb-8 max-lg:grid sm:grid-cols-2 gap-3 sm:gap-6 flex-col"
      data-uk-sticky="media: 1024; end: #js-oversized; offset: 80"
    >
      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">Received Join Requests</h3>
            <p className="font-normal text-sm text-gray-500 leading-6">
              You received {receivedJoinWaypointRequests.length} join requests.
            </p>
          </div>

          {receivedJoinWaypointRequests.length > 0 && (
            <div
              className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
              onClick={() => {
                setExpandReceivedJoinWaypointRequests(
                  !expandReceivedJoinWaypointRequests,
                );
              }}
            >
              {expandReceivedJoinWaypointRequests ? 'Hide' : 'Show'}
            </div>
          )}

          {receivedJoinWaypointRequests.length >
            receivedJoinWaypointRequestsToShow && (
            <div
              className="text-sm text-[#db00ff] cursor-pointer"
              onClick={() => {
                setExpandReceivedJoinWaypointRequests(
                  !expandReceivedJoinWaypointRequests,
                );
              }}
            >
              {expandReceivedJoinWaypointRequests ? 'Show less' : 'Show all'}
            </div>
          )}
        </div>

        {receivedJoinWaypointRequests.length > 0 && (
          <div className="side-list"></div>
        )}
      </div>

      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">Sent Join Requests</h3>
            <p className="font-normal text-sm text-gray-500 leading-6">
              You sent {sentJoinWaypointRequests.length} join requests.
            </p>
          </div>

          {sentJoinWaypointRequests.length > 0 && (
            <div
              className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
              onClick={() => {
                setExpandSentJoinWaypointRequests(
                  !expandSentJoinWaypointRequests,
                );
              }}
            >
              {expandSentJoinWaypointRequests ? 'Show less' : 'Show all'}
            </div>
          )}

          {sentJoinWaypointRequests.length > sentJoinWaypointRequestsToShow && (
            <div
              className="text-sm text-[#db00ff] cursor-pointer"
              onClick={() => {
                setExpandSentJoinWaypointRequests(
                  !expandSentJoinWaypointRequests,
                );
              }}
            >
              {expandSentJoinWaypointRequests ? 'Show less' : 'Show all'}
            </div>
          )}
        </div>

        {sentJoinWaypointRequests.length > 0 && (
          <div className="side-list"></div>
        )}
      </div>
    </div>
  );
};

export default WaypointsSidebar;
