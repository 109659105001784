import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { useMutation, useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import { UserService } from '../services/UserService';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import { useThemeMode } from '../hooks/useThemeMode';
import { IAuthUser } from '../interfaces/auth-user.interface';

const EditAvatarModal = ({ user }: { user: IAuthUser }) => {
  const { isDarkMode } = useThemeMode();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [preview, setPreview] = useState<any>();

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview: string) => {
    setPreview(preview);
  };

  const addAvatarMutation = useMutation(
    (formData: FormData) => {
      return UserService.addAvatar(formData);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['profile']);
      },
      onError: (e: any) => {
        if (e?.response?.status === 422) {
          ErrorToast(t('Errors.avatarRequirements'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  useEffect(() => {
    // @ts-ignore
    if (typeof window?.UIkit !== 'undefined') {
      // @ts-ignore
      UIkit.util.on('#edit-avatar', 'hidden', function () {
        addAvatarMutation.reset();
      });
    }
  }, []);

  return (
    <div id="edit-avatar-modal-wrapper">
      <div
        className="hidden lg:p-20 uk- open"
        id="edit-avatar"
        data-uk-modal="container: #edit-avatar-modal-wrapper;"
      >
        {addAvatarMutation.isLoading && <Loader />}

        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
          <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
            <div className="text-base font-semibold text-black dark:text-white px-10">
              Edit Avatar
            </div>
            <button
              type="button"
              className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {addAvatarMutation.isSuccess ? (
            <>
              <div className="p-5 text-sm text-center font-normal">
                <p>You successfully changed your avatar.</p>

                <p className="mt-3">
                  Our team will review and approve your new avatar within the
                  next 24 hours. As soon as it is approved it will be visible on
                  Playsum.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="p-5 m-auto">
                <Avatar
                  // @ts-ignore
                  width="100%"
                  height={45}
                  imageWidth={320}
                  onCrop={onCrop}
                  onClose={onClose}
                  label="Choose your avatar"
                  backgroundColor={isDarkMode ? '#1E293B' : 'white'}
                  closeIconColor={isDarkMode ? 'white' : 'black'}
                  labelStyle={{
                    color: isDarkMode ? 'white' : 'black',
                    cursor: 'pointer',
                  }}
                  exportMimeType="image/jpeg"
                />
              </div>

              <div className="px-5 pb-5 flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800"
                    onClick={async () => {
                      if (preview) {
                        const imageBlob = await (await fetch(preview)).blob();

                        const formData = new FormData();
                        formData.append(
                          'file',
                          new File([imageBlob], `${user.id}.jpeg`, {
                            type: imageBlob.type,
                          }),
                        );

                        void addAvatarMutation.mutate(formData);
                      }
                    }}
                  >
                    Upload
                  </button>

                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAvatarModal;
