import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';
import Avatar from '../components/Avatar';
import { Link } from 'react-router-dom';
import { FriendshipService } from '../services/FriendshipService';
import RemoveFriendRequestModal from '../components/RemoveFriendRequestModal';
import FriendsSidebar from '../components/FriendsSidebar';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IonIcon } from '@ionic/react';
import { personRemoveOutline } from 'ionicons/icons';

const AccountFriends = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const getAcceptedFriendshipsQuery = useInfiniteQuery(
    ['acceptedFriendships'],
    FriendshipService.getAcceptedFriendRequests,
    {
      getNextPageParam: (result) =>
        result?.friendships?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {getAcceptedFriendshipsQuery.isLoading && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            >
              <div className="flex-1 lg:order-1 order-2">
                <div className="hidden lg:flex sm:mb-6 mb-3 items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Discover Users
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      Find gamers with similar interests.
                    </p>
                  </div>
                </div>

                {getAcceptedFriendshipsQuery?.data && (
                  <InfiniteScroll
                    dataLength={
                      getAcceptedFriendshipsQuery?.data
                        ? getAcceptedFriendshipsQuery?.data?.pages?.flatMap(
                            (page) => page.friendships,
                          )?.length || 0
                        : 0
                    }
                    next={getAcceptedFriendshipsQuery.fetchNextPage}
                    hasMore={getAcceptedFriendshipsQuery.hasNextPage || false}
                    loader={<div className="my-10">Loading more...</div>}
                  >
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                      {getAcceptedFriendshipsQuery?.data?.pages.map(
                        (group, i) => (
                          <React.Fragment key={i}>
                            {group.friendships.map((friendship, idx) => (
                              <div
                                key={idx}
                                className="card flex space-x-5 p-5"
                              >
                                <Link to={`/user/${friendship.user.id}`}>
                                  <div className="card-media w-16 h-16 shrink-0 rounded-full bg-secondery dark:bg-dark3">
                                    <Avatar
                                      userId={friendship.user.id}
                                      username={friendship.user.username}
                                    />
                                  </div>
                                </Link>
                                <div className="card-body flex-1 p-0">
                                  <Link to={`/user/${friendship.user.id}`}>
                                    <h4 className="card-title line-clamp-1">
                                      {friendship.user.username}
                                    </h4>
                                  </Link>

                                  <div className="flex gap-3">
                                    <button
                                      type="button"
                                      className="button bg-secondery text-black dark:text-white"
                                      data-uk-toggle={`target: #remove-friendship-${friendship.id}`}
                                    >
                                      <IonIcon
                                        icon={personRemoveOutline}
                                        className="text-lg"
                                      />
                                      Unfriend
                                    </button>

                                    <RemoveFriendRequestModal
                                      friendship={friendship}
                                      targetId={`remove-friendship-${friendship.id}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        ),
                      )}
                    </div>
                  </InfiniteScroll>
                )}
              </div>

              <div className="lg:w-[400px] lg:order-2 order-1">
                <div className="flex lg:hidden sm:mb-6 mb-3 items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Friends
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      Users you are friends with.
                    </p>
                  </div>
                </div>

                <FriendsSidebar />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AccountFriends;
