import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { heart } from 'ionicons/icons';
import { PostService } from '../services/PostService';
import { IPost } from '../interfaces/post.interface';
import { useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import { useTranslation } from 'react-i18next';

const PostReaction = ({ post }: { post: IPost }) => {
  const { t } = useTranslation();

  const [liked, setLiked] = useState(post.postReaction);
  const [reactionCount, setReactionCount] = useState(post.reactionCount);

  const queryClient = useQueryClient();

  useEffect(() => {
    setLiked(post.postReaction);
    setReactionCount(post.reactionCount);
  }, [post]);

  const toggleLike = async () => {
    const previousLiked = liked;
    const previousReactionCount = reactionCount;

    try {
      setLiked(!previousLiked);

      if (liked) {
        setReactionCount(previousReactionCount - 1);

        await PostService.deleteReaction(post.id);
      } else {
        setReactionCount(previousReactionCount + 1);

        await PostService.addReaction(post.id);
      }

      void queryClient.invalidateQueries(['exploreFeed']);
      void queryClient.invalidateQueries(['followingFeed']);
      void queryClient.invalidateQueries(['userTimeline']);
      void queryClient.invalidateQueries(['post', post.id]);
    } catch {
      setLiked(previousLiked);
      setReactionCount(previousReactionCount);

      ErrorToast(t('Errors.general'));
    }
  };

  return (
    <>
      <button
        type="button"
        className={`button-icon cursor-pointer ${liked ? 'text-red-500 bg-slate-200/70 dark:bg-slate-700 hover:text-inherit hover:bg-slate-200/70 hover:dark:bg-slate-700' : 'bg-slate-200/70 dark:bg-slate-700 hover:text-red-500 hover:bg-slate-200/70 hover:dark:bg-slate-700'}`}
        onClick={toggleLike}
      >
        <IonIcon icon={heart} className="text-lg" />
      </button>
      {reactionCount}
    </>
  );
};

export default PostReaction;
