import React from 'react';
import ButtonPrimary from './Button/ButtonPrimary';

const GeneralError = ({
  statusCode = 500,
  title = 'Something went wrong',
  message = 'An unexpected error occurred. Please try again. If the error persists, please get in touch with us.',
}: {
  statusCode?: number;
  title?: string;
  message?: string;
}) => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center">
      <div className="max-w-[1065px] w-full px-4">
        <div className="flex flex-col items-center justify-center text-center">
          <h1 className="text-8xl font-bold text-slate-900 dark:text-slate-100 mb-4">
            {statusCode}
          </h1>
          <h2 className="text-2xl font-semibold text-slate-800 dark:text-slate-200 mb-6">
            {title}
          </h2>
          <p className="text-slate-600 dark:text-slate-400 mb-8 max-w-md">
            {message}
          </p>
          <ButtonPrimary href="/" className="max-w-[200px]">
            Back to Home
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default GeneralError;
