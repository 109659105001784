import React from 'react';
import GeneralError from './GeneralError';

const NotFoundError = () => {
  return (
    <GeneralError
      statusCode={404}
      title={'Page not found'}
      message={
        'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.'
      }
    />
  );
};

export default NotFoundError;
