import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import { WaypointService } from '../services/WaypointService';
import { IWaypoint } from '../interfaces/waypoint.interface';
import InfoToast from './InfoToast';

const WaypointDetailsModal = ({
  waypoint,
  targetId,
}: {
  waypoint: IWaypoint;
  targetId: string;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const joinWaypointMutation = useMutation(
    (waypointId: string) => {
      return WaypointService.join(waypointId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['waypoints']);
        void queryClient.invalidateQueries(['joinedWaypoints']);

        // @ts-ignore
        if (typeof window?.UIkit !== 'undefined') {
          // @ts-ignore
          UIkit.modal(`#${targetId}`).hide();
        }

        InfoToast('You successfully joined the waypoint.');
      },
      onError: (e: any) => {
        if (e?.response?.status === 409) {
          ErrorToast(t('Errors.waypointAlreadyJoined'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  return (
    <div id="waypoint-details-modal-wrapper">
      <div
        className="hidden lg:p-20 uk- open"
        id={targetId}
        data-uk-modal="container: #waypoint-details-modal-wrapper;"
      >
        {joinWaypointMutation.isLoading && <Loader />}

        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
          <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
            <div className="text-base font-semibold text-black dark:text-white px-10">
              {waypoint.name}
            </div>
            <button
              type="button"
              className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {joinWaypointMutation.isSuccess && waypoint ? (
            <div className="p-5 text-sm">
              <div className="mb-4 last:mb-0">
                <div className="text-sm text-center">
                  <p className="mb-2">
                    Congratulations, you joined{' '}
                    <Link
                      className="text-[#db00ff]"
                      to={`/waypoint/${waypoint.id}/${encodeURIComponent(waypoint.name)}`}
                    >
                      {waypoint.name}
                    </Link>
                  </p>
                  <p>
                    You can now engage with other members of the community in
                    this waypoint.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-5 text-sm">
              <div className="mb-4 last:mb-0">
                <div className="text-sm text-center">
                  {waypoint.description}
                </div>

                <button
                  type="button"
                  className="mt-4 button bg-[#db00ff] text-slate-50 w-full text-base"
                  onClick={() => {
                    void joinWaypointMutation.mutate(waypoint.id);
                  }}
                >
                  Join Waypoint
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaypointDetailsModal;
