import toast from 'react-hot-toast';
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';

const ErrorToast = (message: string) => {
  return toast.custom(
    () => (
      <div className="w-full rounded-lg shadow-sm p-4 max-w-md bg-white dark:bg-slate-800 pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 dark:text-white text-black">
        <div className="flex">
          <IonIcon
            icon={alertCircleOutline}
            className="w-7 h-7 my-auto text-red-500"
          />

          <span className={`ml-2 my-auto block text-base`}>{message}</span>
        </div>
      </div>
    ),
    {
      id: message,
      position: 'top-right',
      duration: 3000,
    },
  );
};

export default ErrorToast;
