import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserFollowService } from '../services/UserFollowService';
import InfoToast from './InfoToast';
import ErrorToast from './ErrorToast';
import { IUser } from '../interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';

const FollowUser = ({
  followee,
  children,
  type,
  className,
}: {
  followee: IUser;
  children: string | JSX.Element | JSX.Element[];
  type: 'a' | 'button';

  className?: string;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const followUserMutation = useMutation(
    () => {
      return UserFollowService.followUser(followee.id);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['userProfile', followee.id]);
        void queryClient.invalidateQueries(['userFollowing']);
        void queryClient.invalidateQueries(['similarUsers']);
        void queryClient.invalidateQueries(['exploreFeed']);
        void queryClient.invalidateQueries(['followingFeed']);

        InfoToast(
          t('FollowUser.successfullyFollowed', { username: followee.username }),
        );
      },
      onError: (e: any) => {
        if (e?.response?.status === 409) {
          ErrorToast(t('Errors.userAlreadyFollowing'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  if (type === 'button') {
    return (
      <>
        {followUserMutation.isLoading && <Loader />}
        <button
          className={className}
          onClick={() => followUserMutation.mutate()}
        >
          {children}
        </button>
      </>
    );
  } else {
    return (
      <>
        {followUserMutation.isLoading && <Loader />}

        <a className={className} onClick={() => followUserMutation.mutate()}>
          {children}
        </a>
      </>
    );
  }
};

export default FollowUser;
