import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';
import { Link } from 'react-router-dom';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WaypointService } from '../services/WaypointService';
import { minidenticon } from 'minidenticons';
import WaypointsSidebar from '../components/WaypointsSidebar';
import LeaveWaypointModal from '../components/LeaveWaypointModal';
import { removeCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const AccountWaypoints = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const getJoinedWaypointsQuery = useInfiniteQuery(
    ['joinedWaypoints'],
    WaypointService.getJoined,
    {
      getNextPageParam: (result) =>
        result?.waypoints?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {getJoinedWaypointsQuery.isLoading && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            >
              <div className="flex-1 lg:order-1 order-2">
                <div className="hidden lg:flex sm:mb-6 mb-3 items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Waypoints
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      The Waypoints you have joined.
                    </p>
                  </div>
                </div>

                {getJoinedWaypointsQuery?.data && (
                  <InfiniteScroll
                    dataLength={
                      getJoinedWaypointsQuery?.data
                        ? getJoinedWaypointsQuery?.data?.pages?.flatMap(
                            (page) => page.waypoints,
                          )?.length || 0
                        : 0
                    }
                    next={getJoinedWaypointsQuery.fetchNextPage}
                    hasMore={getJoinedWaypointsQuery.hasNextPage || false}
                    loader={<div className="my-10">Loading more...</div>}
                  >
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                      {getJoinedWaypointsQuery?.data?.pages.map((group, i) => (
                        <React.Fragment key={i}>
                          {group.waypoints.map((waypoint, idx) => (
                            <div key={idx} className="card flex space-x-5 p-5">
                              <Link
                                to={`/waypoint/${waypoint.id}/${encodeURIComponent(waypoint.name)}`}
                              >
                                <div className="card-media w-16 h-16 shrink-0 rounded-full bg-secondery dark:bg-dark3">
                                  <img
                                    src={
                                      'data:image/svg+xml;utf8,' +
                                      encodeURIComponent(
                                        minidenticon(waypoint.name),
                                      )
                                    }
                                    alt={`${waypoint.name} Avatar`}
                                    className={`rounded-full w-full h-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400`}
                                  />
                                </div>
                              </Link>
                              <div className="card-body flex-1 p-0">
                                <Link
                                  to={`/waypoint/${waypoint.id}/${encodeURIComponent(waypoint.name)}`}
                                >
                                  <h4 className="card-title line-clamp-1">
                                    {waypoint.name}
                                  </h4>
                                </Link>

                                <div className="flex gap-3">
                                  <button
                                    type="button"
                                    className="button bg-secondery text-black dark:text-white"
                                    data-uk-toggle={`target: #leave-waypoint-${waypoint.id}`}
                                  >
                                    <IonIcon
                                      icon={removeCircleOutline}
                                      className="text-lg"
                                    />
                                    Leave
                                  </button>

                                  <LeaveWaypointModal
                                    waypoint={waypoint}
                                    targetId={`leave-waypoint-${waypoint.id}`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </div>

              <div className="lg:w-[400px] lg:order-2 order-1">
                <div className="flex lg:hidden sm:mb-6 mb-3 items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Waypoints
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      The Waypoints you have joined.
                    </p>
                  </div>
                </div>

                <WaypointsSidebar />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AccountWaypoints;
