import { createContext, ReactNode, useState } from 'react';

type PostContextType = {
  selectedPost: string | undefined;
  selectPost: (postId: string | undefined) => void;
};

export const PostContext = createContext<PostContextType>({
  selectedPost: undefined,
  selectPost: () => {},
});

export const PostProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPost, setSelectPost] = useState<string | undefined>();

  const selectPost = (postId: string | undefined) => {
    setSelectPost(postId);
  };

  return (
    <PostContext.Provider value={{ selectedPost, selectPost }}>
      {children}
    </PostContext.Provider>
  );
};
