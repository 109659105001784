import React from 'react';
import { IonIcon } from '@ionic/react';
import { syncOutline } from 'ionicons/icons';
import { useQuery } from 'react-query';
import ErrorToast from './ErrorToast';
import { FriendshipService } from '../services/FriendshipService';
import { useTranslation } from 'react-i18next';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';
import { UserService } from '../services/UserService';
import FollowUser from './FollowUser';

const FeedSidebar = () => {
  const { t } = useTranslation();

  const getRecentlyActiveFriendshipsQuery = useQuery({
    queryKey: ['recentlyActiveFriendships'],
    queryFn: async () => {
      return await FriendshipService.getRecentlyActiveFriends();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });
  const getSimilarUsersQuery = useQuery({
    queryKey: ['similarUsers'],
    queryFn: async () => {
      return await UserService.getSimilarUsers();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  return (
    <div
      className="flex lg:pb-8 max-lg:grid sm:grid-cols-2 gap-3 sm:gap-6 flex-col"
      data-uk-sticky="media: 1024; end: #js-oversized; offset: 80"
    >
      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <h3 className="font-bold text-base">Online Friends</h3>
          <button
            type="button"
            onClick={() => getRecentlyActiveFriendshipsQuery.refetch()}
          >
            {getRecentlyActiveFriendshipsQuery.isLoading && (
              <IonIcon
                icon={syncOutline}
                className={`text-xl spin text-[#db00ff]`}
              />
            )}
          </button>
        </div>

        {!getRecentlyActiveFriendshipsQuery.isLoading &&
          getRecentlyActiveFriendshipsQuery?.data &&
          getRecentlyActiveFriendshipsQuery?.data?.length > 0 && (
            <div className="grid grid-cols-6 gap-3 mt-4">
              {getRecentlyActiveFriendshipsQuery?.data?.map(
                (friendship, idx) => (
                  <Link key={idx} to={`/user/${friendship.user.id}`}>
                    <div className="w-10 h-10 relative">
                      <Avatar
                        userId={friendship.user.id}
                        username={friendship.user.username}
                      />
                    </div>
                  </Link>
                ),
              )}
            </div>
          )}
      </div>

      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">Discover Users</h3>
            <p className="font-normal text-sm text-gray-500 leading-6">
              Users with similar interests
            </p>
          </div>

          {getSimilarUsersQuery.isLoading && (
            <IonIcon
              icon={syncOutline}
              className={`text-xl spin text-[#db00ff]`}
            />
          )}
        </div>

        {!getSimilarUsersQuery?.isLoading && getSimilarUsersQuery?.data && (
          <div className="side-list">
            {getSimilarUsersQuery?.data?.map((user, idx) => (
              <div key={idx} className="side-list-item">
                <Link
                  to={`/user/${user.id}`}
                  className="side-list-image rounded-full"
                >
                  <Avatar userId={user.id} username={user.username} />
                </Link>

                <div className="flex-1">
                  <Link to={`/user/${user.id}`}>
                    <h4 className="side-list-title">{user.username}</h4>
                  </Link>
                </div>
                <FollowUser
                  followee={user}
                  type={'button'}
                  className={
                    'button text-sm rounded-lg py-1.5 bg-secondery px-8'
                  }
                >
                  Follow
                </FollowUser>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedSidebar;
