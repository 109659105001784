import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IonIcon } from '@ionic/react';
import { personAddOutline } from 'ionicons/icons';
import { UserService } from '../services/UserService';
import { Link } from 'react-router-dom';
import Avatar from '../components/Avatar';
import AddFriendRequestModal from '../components/AddFriendRequestModal';

const UserSearch = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [filter, setFilter] = useState('');
  const [hasNoGames, setHasNoGames] = useState(false);

  const getUserGamesQuery = useQuery({
    queryKey: ['userGames', user?.id],
    queryFn: async () => {
      return await UserService.getUserGames({
        // @ts-ignore
        queryKey: ['userGames', user?.id as string],
      });
    },
    onSuccess: async ({ userGames }) => {
      if (userGames?.length === 0) {
        setHasNoGames(true);
      } else {
        setHasNoGames(false);
      }
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
    enabled: !!user,
  });

  const getUsersQuery = useInfiniteQuery(
    ['users', filter],
    // @ts-ignore
    UserService.searchUsers,
    {
      getNextPageParam: (result) =>
        result?.users?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  useEffect(() => {
    if (hasNoGames) {
      // @ts-ignore
      if (typeof window?.UIkit !== 'undefined') {
        // @ts-ignore
        UIkit.modal('#no-games').show();
      }
    }
  }, [hasNoGames]);

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(getUserGamesQuery.isLoading || getUsersQuery.isLoading) && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />
            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>
            <div className="flex-1">
              <>
                <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Discover Users
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      Find gamers with similar interests.
                    </p>
                  </div>

                  <div className="flex items-center gap-3 my-auto">
                    <p className="font-normal text-sm">Filter by</p>
                    <select
                      className="w-36 dark:bg-slate-800"
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <option value="">All Users</option>
                      <option value="games">My Games</option>
                      <option value="platforms">My Platforms</option>
                    </select>
                  </div>
                </div>

                {getUsersQuery?.data && (
                  <InfiniteScroll
                    dataLength={
                      getUsersQuery?.data
                        ? getUsersQuery?.data?.pages?.flatMap(
                            (page) => page.users,
                          )?.length || 0
                        : 0
                    }
                    next={getUsersQuery.fetchNextPage}
                    hasMore={getUsersQuery.hasNextPage || false}
                    loader={<div className="my-10">Loading more...</div>}
                  >
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3">
                      {getUsersQuery?.data?.pages.map((group, i) => (
                        <React.Fragment key={i}>
                          {group.users.map((user, idx) => (
                            <div key={idx} className="card flex space-x-5 p-5">
                              <Link to={`/user/${user.id}`}>
                                <div className="card-media w-16 h-16 shrink-0 rounded-full bg-secondery dark:bg-dark3">
                                  <Avatar
                                    userId={user.id}
                                    username={user.username}
                                  />
                                </div>
                              </Link>
                              <div className="card-body flex-1 p-0">
                                <Link to={`/user/${user.id}`}>
                                  <h4 className="card-title line-clamp-1">
                                    {user.username}
                                  </h4>
                                </Link>

                                <div className="flex gap-3">
                                  <button
                                    type="button"
                                    className="button bg-secondery text-black dark:text-white"
                                    data-uk-toggle={`target: #add-friend-request-${user.id}`}
                                  >
                                    <IonIcon
                                      icon={personAddOutline}
                                      className="text-lg"
                                    />
                                    Add as friend
                                  </button>

                                  <AddFriendRequestModal
                                    user={user}
                                    targetId={`add-friend-request-${user.id}`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </>
            </div>
          </>
        )}
      </div>

      <div id="no-games-modal-wrapper">
        <div
          className="hidden lg:p-20 uk- open"
          id="no-games"
          data-uk-modal="container: #no-games-modal-wrapper;  bgClose: false; escClose: false"
        >
          <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
            <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
              <div className="text-base font-semibold text-black dark:text-white px-10">
                Finish Profile Setup
              </div>
            </div>
            <div className="p-5 text-sm">
              <div className="mb-4 last:mb-0">
                <div className="text-sm text-center mb-3">
                  {"You didn't add any games yet."}
                </div>
                <div className="text-sm text-center">
                  In order to discover and connect with other members you need
                  to add games to your{' '}
                  <Link to="/account/game-library" className="text-[#db00ff]">
                    game library
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserSearch;
