import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { UserService } from '../services/UserService';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import INoPackshot from '../assets/images/no-packshot.png';
import { useDebounce } from '@react-hooks-hub/use-debounce';
import { Platform } from '../enums/platform.enum';
import { GameService } from '../services/GameService';
import { PAGINATION_DEFAULT } from '../constants';
import UserGame from '../components/UserGame';
import { useAuthStore } from '../store/zustand';
import InfiniteScroll from 'react-infinite-scroll-component';
import InfoToast from '../components/InfoToast';

const AccountGames = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const user = useAuthStore((state) => state.user);

  const getUserGamesQuery = useInfiniteQuery(
    ['userGames', user?.id],
    // @ts-ignore
    UserService.getUserGames,
    {
      getNextPageParam: (result) =>
        result?.userGames?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
      enabled: !!user,
    },
  );

  const searchGamesMutation = useMutation(
    (title: string) => {
      return GameService.search(title);
    },
    {
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const addProfileGameMutation = useMutation(
    ({ gameId, platform }: { gameId: string; platform: Platform }) => {
      return GameService.addProfileGame(gameId, platform);
    },
    {
      onSuccess: (userGame) => {
        void queryClient.invalidateQueries(['userGames']);

        InfoToast(
          `You successfully added ${userGame.title} for the given platform.`,
        );
      },
      onError: (e: any) => {
        if (e?.response?.status === 409) {
          ErrorToast(t('Errors.profileGameAlreadyAdded'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  const debouncedGameSearch = useDebounce(searchGamesMutation.mutate, 300);

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(getUserGamesQuery.isLoading ||
          searchGamesMutation.isLoading ||
          addProfileGameMutation.isLoading) && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>

            <div className="flex-1">
              <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                <div>
                  <h2 className="md:text-lg text-base font-semibold text-black">
                    Game Library
                  </h2>
                  <p className="font-normal text-sm text-gray-500 leading-6">
                    Add games to your library that you own.
                  </p>
                </div>
              </div>

              {getUserGamesQuery?.data &&
                getUserGamesQuery.data?.pages?.length !== 0 && (
                  <InfiniteScroll
                    dataLength={
                      getUserGamesQuery?.data
                        ? getUserGamesQuery?.data?.pages?.flatMap(
                            (page) => page.userGames,
                          )?.length || 0
                        : 0
                    }
                    next={getUserGamesQuery.fetchNextPage}
                    hasMore={getUserGamesQuery.hasNextPage || false}
                    loader={null}
                  >
                    <div className="grid sm:grid-cols-5 md:grid-cols-6 xl:grid-cols-7 grid-cols-2 gap-3">
                      <div
                        className="cursor-pointer card min-h-[218px]"
                        data-uk-toggle="target: #game-search"
                      >
                        <div className="flex items-center justify-center h-full">
                          <div className="flex flex-col">
                            <i
                              className={`la la-plus-circle text-4xl text-center mb-3`}
                            />
                            Add Games
                          </div>
                        </div>
                      </div>
                      {getUserGamesQuery?.data?.pages?.map((group, i) => (
                        <React.Fragment key={i}>
                          {group.userGames.map((userGame, idx) => (
                            <UserGame
                              key={idx}
                              userGame={userGame}
                              enableEdit={true}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
            </div>

            <div id="game-search-modal-wrapper">
              <div
                className="hidden lg:p-20 uk- open"
                id="game-search"
                data-uk-modal="container: #game-search-modal-wrapper;"
              >
                <div className="uk-modal-dialog tt relative overflow-hidden mx-auto shadow-xl rounded-lg bg-bgbody dark:bg-slate-800 md:w-[720px] w-full">
                  <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
                    <div className="text-base font-semibold text-black dark:text-white px-10">
                      Game search
                    </div>
                    <button
                      type="button"
                      className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="p-5">
                    <div id="search--box">
                      <input
                        type="text"
                        placeholder="Search games"
                        className="w-full"
                        onChange={(e) => debouncedGameSearch(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="px-5 pb-7 overflow-auto">
                    <div className="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
                      {searchGamesMutation?.data?.map((discoveredGame, idx) => {
                        return (
                          <div key={idx}>
                            <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                              <div>
                                <div className="aspect-auto bg-bgbody">
                                  <img
                                    className="object-cover"
                                    src={`${discoveredGame?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + discoveredGame.imageId + '.jpg' : INoPackshot}`}
                                    alt={`${discoveredGame.title} Cover`}
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <h4
                                  className="card-title text-sm line-clamp-1"
                                  data-uk-tooltip={`title: ${discoveredGame.title}; pos: top-left;`}
                                >
                                  {discoveredGame.title}
                                </h4>
                                <div className="flex gap-2 mt-2">
                                  <select
                                    id="platforms"
                                    className="w-full py-1.5 px-2.5 font-normal"
                                    onChange={(e) => {
                                      if (e.target.value === 'invalid') {
                                        return;
                                      }

                                      addProfileGameMutation.mutate({
                                        gameId: discoveredGame.id,
                                        platform: e.target.value as Platform,
                                      });
                                    }}
                                  >
                                    <option value="invalid">
                                      Add Platform
                                    </option>
                                    {discoveredGame.platforms.map(
                                      (platform) => {
                                        return (
                                          <option
                                            key={platform}
                                            value={platform}
                                          >
                                            {/*@ts-ignore*/}
                                            {t(`Platforms.${platform}`)}
                                          </option>
                                        );
                                      },
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AccountGames;
