import INoBanner from '../assets/images/no-banner.png';
import INoPackshot from '../assets/images/no-packshot.png';

export const getImage = (
  filePath: string | undefined,
  fallback?: 'packshot' | 'banner',
): string => {
  if (filePath) {
    return process.env.REACT_APP_CDN_HOST + filePath;
  } else if (fallback) {
    return fallback === 'banner' ? INoBanner : INoPackshot;
  } else {
    return '';
  }
};

export const getAvatar = (userId: string): string => {
  return `${process.env.REACT_APP_CDN_HOST}images/avatars/${userId}.jpeg`;
};
