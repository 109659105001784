import Api from '../utils/api';
import { IDiscoveredGame } from '../interfaces/discovered-game.interface';
import { Platform } from '../enums/platform.enum';
import { IUserGame } from '../interfaces/user-game.interface';

export const GameService = {
  search: async function (title: string): Promise<IDiscoveredGame[]> {
    const { data } = await Api.get<IDiscoveredGame[]>(
      `/game/search?title=${title}`,
    );

    return data;
  },
  addProfileGame: async function (
    gameId: string,
    platform: Platform,
  ): Promise<IUserGame> {
    const { data } = await Api.post<IUserGame>(`/user/profile/games`, {
      gameId: gameId,
      platform: platform,
    });

    return data;
  },
  deleteProfileGame: async function (
    gameId: string,
    platform: Platform,
  ): Promise<void> {
    await Api.delete(`/user/profile/games/${gameId}/${platform}`);
  },
};
