import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from '../components/Post';
import { PAGINATION_DEFAULT } from '../constants';
import { useAuthStore } from '../store/zustand';
import { FeedService } from '../services/FeedService';
import { IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import FeedSidebar from '../components/FeedSidebar';
import PostDetail from '../components/PostDetail';
import CreatePostModal from '../components/CreatePostModal';

const Feed = () => {
  const { t } = useTranslation();

  const currentUser = useAuthStore((state) => state.user);

  const [currentTab, setCurrentTab] = useState<'discover' | 'following'>(
    'following',
  );

  const getExploreFeedQuery = useInfiniteQuery(
    ['exploreFeed'],
    // @ts-ignore
    FeedService.getExploreFeed,
    {
      getNextPageParam: (result) =>
        result?.posts?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
      enabled: currentTab === 'discover',
    },
  );

  const getFollowingFeedQuery = useInfiniteQuery(
    ['followingFeed'],
    // @ts-ignore
    FeedService.getFollowingFeed,
    {
      getNextPageParam: (result) =>
        result?.posts?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
      enabled: currentTab === 'following',
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(getExploreFeedQuery.isLoading || getFollowingFeedQuery.isLoading) && (
          <Loader />
        )}

        {currentUser && (
          <>
            <AccountHeader user={currentUser}>
              <div className="flex items-center justify-between mt-3 border-t border-gray-100 px-2 max-lg:flex-col dark:border-slate-700">
                <nav
                  className="flex gap-0.5 rounded-lg -mb-px text-gray-600 font-medium text-[15px] dark:text-white max-md:w-full max-md:overflow-x-auto cursor-pointer"
                  data-uk-switcher={`animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium;`}
                >
                  <div
                    className={`inline-block py-3 leading-8 px-3.5 ${currentTab === 'following' ? 'border-b-2 border-[#db00ff] text-[#db00ff]' : ''}`}
                    onClick={() => setCurrentTab('following')}
                  >
                    Following
                  </div>

                  <div
                    className={`inline-block py-3 leading-8 px-3.5 ${currentTab === 'discover' ? 'border-b-2 border-[#db00ff] text-[#db00ff]' : ''}`}
                    onClick={() => setCurrentTab('discover')}
                  >
                    Discover
                  </div>
                </nav>
              </div>
            </AccountHeader>

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            >
              <div className="flex-1 lg:order-1 order-2 space-y-6">
                <>
                  <div id="page-tabs" className="uk-switcher">
                    <div
                      className={`${currentTab === 'following' ? 'uk-active' : ''}`}
                    >
                      {getFollowingFeedQuery.data && (
                        <InfiniteScroll
                          dataLength={
                            getFollowingFeedQuery?.data
                              ? getFollowingFeedQuery?.data?.pages?.flatMap(
                                  (page) => page.posts,
                                )?.length || 0
                              : 0
                          }
                          next={getFollowingFeedQuery.fetchNextPage}
                          hasMore={getFollowingFeedQuery.hasNextPage || false}
                          loader={<div className="my-10">Loading more...</div>}
                        >
                          <div className="space-y-6">
                            {getFollowingFeedQuery?.data?.pages.map(
                              (group, i) => (
                                <React.Fragment key={i}>
                                  {group.posts.map((post, idx) => (
                                    <Post
                                      key={idx}
                                      currentUser={currentUser}
                                      post={post}
                                    />
                                  ))}
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        </InfiniteScroll>
                      )}
                    </div>

                    <div
                      className={`${currentTab === 'discover' ? 'uk-active' : ''}`}
                    >
                      {getExploreFeedQuery.data && (
                        <InfiniteScroll
                          dataLength={
                            getExploreFeedQuery?.data
                              ? getExploreFeedQuery?.data?.pages?.flatMap(
                                  (page) => page.posts,
                                )?.length || 0
                              : 0
                          }
                          next={getExploreFeedQuery.fetchNextPage}
                          hasMore={getExploreFeedQuery.hasNextPage || false}
                          loader={<div className="my-10">Loading more...</div>}
                        >
                          <div className="space-y-6">
                            {getExploreFeedQuery?.data?.pages.map(
                              (group, i) => (
                                <React.Fragment key={i}>
                                  {group.posts.map((post, idx) => (
                                    <Post
                                      key={idx}
                                      currentUser={currentUser}
                                      post={post}
                                    />
                                  ))}
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                </>
              </div>

              <div className="lg:w-[400px] lg:order-2 order-1 hidden md:block">
                <FeedSidebar />
              </div>
            </div>

            <PostDetail currentUser={currentUser} />

            <CreatePostModal targetId={`create-post`} />

            <div>
              <button
                type="button"
                className="sm:m-10 m-5 px-4 py-2.5 rounded-2xl bg-gradient-to-tr from-[#e232ff] to-[#db00ff] text-white shadow fixed bottom-0 right-0 group flex items-center gap-1"
                data-uk-toggle={`target: #create-post`}
              >
                <IonIcon icon={addCircleOutline} className="text-2xl" />

                <div className="text-base font-semibold">Post</div>
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Feed;
