import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { FriendshipService } from '../services/FriendshipService';
import ErrorToast from './ErrorToast';
import { IFriendship } from '../interfaces/friendship.interface';
import Loader from './Loader';
import InfoToast from './InfoToast';

const RejectFriendRequestModal = ({
  friendship,
  targetId,
}: {
  friendship: IFriendship;
  targetId: string;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const rejectFriendshipMutation = useMutation(
    (friendshipId: string) => {
      return FriendshipService.rejectFriendRequest(friendshipId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['acceptedFriendships']);
        void queryClient.invalidateQueries(['receivedFriendships']);
        void queryClient.invalidateQueries(['userNotifications']);

        // @ts-ignore
        if (typeof window?.UIkit !== 'undefined') {
          // @ts-ignore
          UIkit.modal(`#${targetId}`).hide();
        }

        InfoToast('You successfully rejected the friend request.');
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <div id="reject-friend-request-modal-wrapper">
      <div
        className="hidden lg:p-20 uk- open"
        id={targetId}
        data-uk-modal="container: #reject-friend-request-modal-wrapper;"
      >
        {rejectFriendshipMutation.isLoading && <Loader />}

        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
          <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
            <div className="text-base font-semibold text-black dark:text-white px-10">
              Reject Friend Request
            </div>
            <button
              type="button"
              className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <>
            <div className="p-5 text-sm">
              Are you sure you want to reject this friend request?
            </div>
            <div className="px-5 pb-5 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800"
                  onClick={() => {
                    void rejectFriendshipMutation.mutate(friendship.id);
                  }}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                >
                  No
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default RejectFriendRequestModal;
