import Api from '../utils/api';
import { IPost } from '../interfaces/post.interface';

export const FeedService = {
  createPost: async function (formData: FormData) {
    await Api.post<void>('/feed/post', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFollowingFeed: async function ({
    pageParam = 0,
  }): Promise<{ posts: IPost[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/feed/following?offset=${pageParam}`;

    const { data } = await Api.get<IPost[]>(url);

    return { posts: data, offset: pageParam };
  },
  getExploreFeed: async function ({
    pageParam = 0,
  }): Promise<{ posts: IPost[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/feed/explore?offset=${pageParam}`;

    const { data } = await Api.get<IPost[]>(url);

    return { posts: data, offset: pageParam };
  },
};
