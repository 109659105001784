import React from 'react';
import INoPackshot from '../assets/images/no-packshot.png';
import { IUserGame } from '../interfaces/user-game.interface';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import { GameService } from '../services/GameService';
import { Platform } from '../enums/platform.enum';
import Loader from './Loader';
import InfoToast from './InfoToast';

const UserGame = ({
  userGame,
  enableEdit = false,
}: {
  userGame: IUserGame;
  enableEdit?: boolean;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const removeProfileGameMutation = useMutation(
    ({ gameId, platform }: { gameId: string; platform: Platform }) => {
      return GameService.deleteProfileGame(gameId, platform);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['userGames']);

        InfoToast('You successfully removed the games platform.');
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <div className="card uk-transition-toggle">
      {removeProfileGameMutation.isLoading && <Loader />}

      <div className="card-media aspect-auto">
        <img
          className="!relative object-cover"
          src={`${userGame?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + userGame.imageId + '.jpg' : INoPackshot}`}
          alt={`${userGame.title} Cover`}
        />
      </div>
      <div className="card-body">
        <h4
          className="card-title line-clamp-1"
          data-uk-tooltip={`title: ${userGame.title}; pos: top-left;`}
        >
          {userGame.title}
        </h4>

        {enableEdit ? (
          <div className="flex gap-2 mt-2">
            <select
              id="platforms"
              className="w-full py-1.5 px-2.5 font-normal"
              onChange={(e) => {
                if (e.target.value === 'invalid') {
                  return;
                }

                removeProfileGameMutation.mutate({
                  gameId: userGame.gameId,
                  platform: e.target.value as Platform,
                });
              }}
            >
              <option value="invalid">Remove Platform</option>
              {userGame.platforms.map((platform) => {
                return (
                  <option key={platform} value={platform}>
                    {/*@ts-ignore*/}
                    {t(`Platforms.${platform}`)}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <p
            className="card-text line-clamp-1"
            /* @ts-ignore */
            data-uk-tooltip={`title: ${userGame.platforms.map((platform) => t('Platforms.' + platform)).join(', ')}; pos: top-left;`}
          >
            {userGame.platforms
              .map((platform) =>
                // @ts-ignore
                t(`Platforms.${platform}`),
              )
              .join(', ')}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserGame;
