import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/zustand';
import { Role } from '../enums/role.enum';

const ProtectedRoute = () => {
  const location = useLocation();

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user = useAuthStore((state) => state.user);

  if (isLoggedIn === undefined) {
    return null;
  }

  return isLoggedIn && user ? (
    location.pathname === '/partner-program' &&
    !user?.roles.includes(Role.AFFILIATE_PARTNER) ? (
      <Navigate to="/" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
