import React from 'react';
import { IonIcon } from '@ionic/react';
import { paperPlaneOutline } from 'ionicons/icons';
import { IPost } from '../interfaces/post.interface';
import InfoToast from './InfoToast';

const PostShare = ({
  post,
  iconSize,
  classNames = 'button-icon ml-auto',
}: {
  post: IPost;
  iconSize: 'lg' | 'xl';
  classNames?: string;
}) => {
  return (
    <button
      type="button"
      className={classNames}
      onClick={async () => {
        await navigator.clipboard.writeText(
          `${process.env.REACT_APP_APP_URL}/user/${post.user.id}?post=` +
            post.id,
        );

        InfoToast('Post url copied to clipboard.');
      }}
    >
      <IonIcon icon={paperPlaneOutline} className={`text-${iconSize}`} />
    </button>
  );
};

export default PostShare;
