import { Outlet, useSearchParams } from 'react-router-dom';
import { useAuthStore } from '../store/zustand';
import RedirectRoute from './RedirectRoute';

const PublicRoute = () => {
  const [searchParams] = useSearchParams();

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn === undefined) {
    return null;
  }

  return !isLoggedIn ? (
    <Outlet />
  ) : (
    <>
      {searchParams.has('redirect_to') ? (
        <RedirectRoute url={searchParams.get('redirect_to') as string} />
      ) : (
        <RedirectRoute url={'/'} />
      )}
    </>
  );
};

export default PublicRoute;
