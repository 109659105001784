import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';
import { WaypointService } from '../services/WaypointService';
import { PAGINATION_DEFAULT } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { minidenticon } from 'minidenticons';
import { IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import WaypointDetailsModal from '../components/WaypointDetailsModal';

const WaypointSearch = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const getWaypointsQuery = useInfiniteQuery(
    ['waypoints'],
    // @ts-ignore
    WaypointService.searchWaypoints,
    {
      getNextPageParam: (result) =>
        result?.waypoints?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {getWaypointsQuery.isLoading && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>
            <div className="flex-1">
              <>
                <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                  <div>
                    <h2 className="md:text-lg text-base font-semibold text-black">
                      Discover Waypoints
                    </h2>
                    <p className="font-normal text-sm text-gray-500 leading-6">
                      Find waypoints and start interacting with other members.
                    </p>
                  </div>
                </div>

                {getWaypointsQuery?.data && (
                  <InfiniteScroll
                    dataLength={
                      getWaypointsQuery?.data
                        ? getWaypointsQuery?.data?.pages?.flatMap(
                            (page) => page.waypoints,
                          )?.length || 0
                        : 0
                    }
                    next={getWaypointsQuery.fetchNextPage}
                    hasMore={getWaypointsQuery.hasNextPage || false}
                    loader={<div className="my-10">Loading more...</div>}
                  >
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3">
                      {getWaypointsQuery?.data?.pages.map((group, i) => (
                        <React.Fragment key={i}>
                          {group.waypoints.map((waypoint, idx) => (
                            <div key={idx} className="card flex space-x-5 p-5">
                              <div className="card-media w-16 h-16 shrink-0 rounded-full bg-secondery dark:bg-dark3">
                                <img
                                  src={
                                    'data:image/svg+xml;utf8,' +
                                    encodeURIComponent(
                                      minidenticon(waypoint.name),
                                    )
                                  }
                                  alt={`${waypoint.name} Avatar`}
                                  className={`rounded-full w-full h-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400`}
                                />
                              </div>
                              <div className="card-body flex-1 p-0">
                                <h4 className="card-title line-clamp-1">
                                  {waypoint.name}
                                </h4>

                                <div className="flex gap-3">
                                  <button
                                    type="button"
                                    className="button bg-secondery text-black dark:text-white"
                                    data-uk-toggle={`target: #waypoint-details-${waypoint.id}`}
                                  >
                                    <IonIcon
                                      icon={addCircleOutline}
                                      className="text-lg"
                                    />
                                    Join Waypoint
                                  </button>

                                  <WaypointDetailsModal
                                    waypoint={waypoint}
                                    targetId={`waypoint-details-${waypoint.id}`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default WaypointSearch;
