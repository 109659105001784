import { IAuthUser } from '../interfaces/auth-user.interface';
import React, { useState } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import ErrorToast from './ErrorToast';
import { PostService } from '../services/PostService';
import { useTranslation } from 'react-i18next';
import { getImage } from '../utils/image';
import { IonIcon } from '@ionic/react';
import {
  chatbubbleEllipses,
  chevronBack,
  chevronForward,
} from 'ionicons/icons';
import { Link, useSearchParams } from 'react-router-dom';
import Avatar from './Avatar';
import { timeAgo } from '../utils/time';
import Loader from './Loader';
import { PAGINATION_DEFAULT, POST_COMMENT_MAX_LENGTH } from '../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostReaction from './PostReaction';
import PostShare from './PostShare';
import PostReport from './PostReport';
import InfoToast from './InfoToast';
import { usePost } from '../hooks/usePost';
import Linkify from 'linkify-react';

const PostDetail = ({ currentUser }: { currentUser: IAuthUser }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedPost, selectPost } = usePost();

  const [comment, setComment] = useState('');

  const getPostQuery = useQuery({
    queryKey: ['post', selectedPost],
    queryFn: async () => {
      return await PostService.getPost(selectedPost as string);
    },
    onSuccess: () => {
      // @ts-ignore
      if (typeof window?.UIkit !== 'undefined') {
        // @ts-ignore
        UIkit.modal('#post-detail').show();

        // @ts-ignore
        UIkit.util.on('#post-detail', 'hidden', function () {
          selectPost(undefined);

          if (searchParams.has('post')) {
            searchParams.delete('post');

            setSearchParams(searchParams);
          }
        });
      }
    },
    onError: async (e: any) => {
      if (e?.response?.status === 404) {
        ErrorToast(t('Errors.postNotFound'));
      } else {
        ErrorToast(t('Errors.general'));
      }
    },
    enabled: !!selectedPost,
  });

  const addPostCommentMutation = useMutation(
    (postId: string) => {
      return PostService.addComment(postId, comment);
    },
    {
      onSuccess: () => {
        setComment('');

        InfoToast(t('PostComment.successfullyCommented'));

        setTimeout(function () {
          void queryClient.invalidateQueries(['exploreFeed']);
          void queryClient.invalidateQueries(['followingFeed']);
          void queryClient.invalidateQueries(['userTimeline']);
          void queryClient.invalidateQueries(['post', selectedPost]);
          void queryClient.invalidateQueries(['postComments', selectedPost]);
        }, 3000);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const getPostCommentsQuery = useInfiniteQuery(
    ['postComments', selectedPost],
    // @ts-ignore
    PostService.getComments,
    {
      getNextPageParam: (result) =>
        result?.postComments?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
      enabled: !!selectedPost,
    },
  );

  const deletePostCommentMutation = useMutation(
    (commentId: string) => {
      return PostService.deleteComment(commentId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['exploreFeed']);
        void queryClient.invalidateQueries(['followingFeed']);
        void queryClient.invalidateQueries(['userTimeline']);
        void queryClient.invalidateQueries(['post', selectedPost]);
        void queryClient.invalidateQueries(['postComments', selectedPost]);

        InfoToast(t('PostComment.successfullyDeleted'));
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <div id={`post-detail-wrapper`}>
      <div
        className="hidden lg:p-20 max-lg:!items-start"
        id={`post-detail`}
        data-uk-modal={`container: #post-detail-wrapper;`}
      >
        {(getPostQuery.isLoading ||
          getPostCommentsQuery.isLoading ||
          deletePostCommentMutation.isLoading) && <Loader />}

        <>
          <div className="uk-modal-dialog tt relative mx-auto overflow-hidden shadow-xl rounded-lg lg:flex items-center ax-w-[86rem] w-full lg:h-[80vh]">
            {getPostQuery?.data && (
              <>
                {getPostQuery.data.attachments?.length > 0 && (
                  <div className="lg:h-full lg:w-[calc(100vw-600px)] w-full flex justify-center items-center relative bg-white dark:bg-dark2">
                    <div
                      className="relative z-10 w-full h-full"
                      tabIndex={-1}
                      data-uk-slideshow="animation: push;ratio: 4:3"
                    >
                      <ul className="uk-slideshow-items overflow-hidden rounded-xl">
                        {getPostQuery.data.attachments?.map(
                          (attachment, idx) => (
                            <li key={idx} className="w-full">
                              <img
                                src={getImage(attachment.path)}
                                alt=""
                                className="w-full h-full object-cover absolute"
                              />
                            </li>
                          ),
                        )}
                      </ul>

                      <a
                        className="nav-prev left-6"
                        href="#"
                        data-uk-slideshow-item="previous"
                      >
                        <IonIcon icon={chevronBack} className="text-2xl" />
                      </a>
                      <a
                        className="nav-next right-6"
                        href="#"
                        data-uk-slideshow-item="next"
                      >
                        <IonIcon icon={chevronForward} className="text-2xl" />
                      </a>
                    </div>
                  </div>
                )}

                <div
                  className={`${getPostQuery.data.attachments?.length > 0 ? 'lg:w-[600px]' : ''} w-full bg-white h-full relative  overflow-y-auto shadow-xl dark:bg-dark2 flex flex-col justify-between`}
                >
                  <button
                    type="button"
                    className="bg-white rounded-full p-2 absolute right-0 top-0 m-3 uk-animation-slide-right-medium z-10 dark:bg-slate-600 uk-modal-close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  <div className="p-5 pb-0">
                    <div className="flex gap-3 text-sm font-medium">
                      <Link to={`/user/${getPostQuery.data.user.id}`}>
                        <div className="w-9 h-9">
                          <Avatar
                            userId={getPostQuery.data.user.id}
                            username={getPostQuery.data.user.username}
                          />
                        </div>
                      </Link>
                      <div className="flex-1">
                        <Link to={`/user/${getPostQuery.data.user.id}`}>
                          <h4 className="text-black dark:text-white">
                            {getPostQuery.data.user.username}
                          </h4>
                        </Link>
                        <div className="text-xs text-gray-500 dark:text-white/80">
                          {timeAgo(new Date(getPostQuery.data.createdAt), 'en')}
                        </div>
                      </div>
                    </div>

                    <p className="font-normal text-sm leading-6 mt-4 whitespace-pre-line">
                      <Linkify
                        options={{
                          className: 'text-[#db00ff]',
                          target: '_blank',
                        }}
                      >
                        {getPostQuery.data.content}
                      </Linkify>
                    </p>

                    <div className="shadow relative -mx-5 px-5 py-3 mt-3">
                      <div className="flex items-center gap-4 text-xs font-semibold">
                        <div className="flex items-center gap-2.5">
                          <PostReaction post={getPostQuery.data} />
                        </div>
                        <div className="flex items-center gap-3">
                          <button
                            type="button"
                            className="button-icon bg-slate-100 dark:bg-slate-700"
                          >
                            <IonIcon
                              icon={chatbubbleEllipses}
                              className="text-lg"
                            />
                          </button>
                          <span>{getPostQuery.data.commentCount}</span>
                        </div>
                        <PostShare post={getPostQuery.data} iconSize="lg" />

                        <PostReport
                          post={getPostQuery.data}
                          iconSize="lg"
                          classNames="button-icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="p-5 h-full overflow-y-auto flex-1 scrollbar-thin scrollbar-track-white scrollbar-thumb-[#E9EAED] dark:scrollbar-thumb-bgbody dark:scrollbar-track-[#1E293B]"
                    id={`scrollablePostComments`}
                  >
                    {getPostCommentsQuery?.data &&
                      getPostCommentsQuery.data?.pages?.length !== 0 && (
                        <InfiniteScroll
                          scrollableTarget={`scrollablePostComments`}
                          dataLength={
                            getPostCommentsQuery?.data
                              ? getPostCommentsQuery?.data?.pages?.flatMap(
                                  (page) => page.postComments,
                                )?.length || 0
                              : 0
                          }
                          next={getPostCommentsQuery.fetchNextPage}
                          hasMore={getPostCommentsQuery.hasNextPage || false} // Replace with a condition based on your data source
                          loader={null}
                        >
                          <div className="relative text-sm font-medium space-y-5">
                            {getPostCommentsQuery?.data?.pages.map(
                              (group, i) => (
                                <React.Fragment key={i}>
                                  {group.postComments.map(
                                    (postComment, idx) => (
                                      <div
                                        key={idx}
                                        className="flex items-start gap-3 relative"
                                      >
                                        <div className="w-6 h-6 mt-1 rounded-full">
                                          <Link
                                            to={`/user/${postComment.user.id}`}
                                          >
                                            <Avatar
                                              userId={postComment.user.id}
                                              username={
                                                postComment.user.username
                                              }
                                            />
                                          </Link>
                                        </div>
                                        <div className="flex-1">
                                          <div className="flex gap-2">
                                            <Link
                                              to={`/user/${postComment.user.id}`}
                                              className="text-black font-medium inline-block dark:text-white"
                                            >
                                              {postComment.user.username}
                                            </Link>
                                            <div className="text-xs text-gray-500 dark:text-white/80 my-auto">
                                              {timeAgo(
                                                new Date(postComment.createdAt),
                                                'en',
                                              )}
                                            </div>

                                            {currentUser.id ===
                                              postComment.user.id && (
                                              <>
                                                {' - '}
                                                <div
                                                  className="cursor-pointer text-xs text-red-400 my-auto"
                                                  onClick={() =>
                                                    deletePostCommentMutation.mutate(
                                                      postComment.id,
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          <p className="mt-0.5 whitespace-pre-line">
                                            <Linkify
                                              options={{
                                                className: 'text-[#db00ff]',
                                                target: '_blank',
                                              }}
                                            >
                                              {postComment.comment}
                                            </Linkify>
                                          </p>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        </InfiniteScroll>
                      )}
                  </div>

                  <div className="bg-white p-3 text-sm font-medium flex items-center gap-2 dark:bg-dark2 overflow-x-hidden">
                    <div className="w-6 h-6">
                      <Avatar
                        userId={currentUser.id}
                        username={currentUser.username}
                      />
                    </div>

                    <div className="flex-1 relative h-10">
                      <textarea
                        placeholder="Add Comment...."
                        rows={1}
                        maxLength={POST_COMMENT_MAX_LENGTH}
                        value={comment}
                        className="w-full resize-none px-4 py-2"
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="text-sm rounded-lg py-2 px-3.5 bg-secondery"
                      onClick={() => {
                        if (comment.trim() !== '' && selectedPost) {
                          void addPostCommentMutation.mutate(selectedPost);
                        }
                      }}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default PostDetail;
