import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';
import { UserService } from '../services/UserService';
import { AffiliateSaleService } from '../services/AffiliateSaleService';
import { PAGINATION_DEFAULT } from '../constants';
import { AffiliateSaleStatus } from '../enums/affiliate-sale-status.enum';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Currency } from '../enums/currency.enum';
import RequestAffiliateSalePayoutModal from '../components/RequestAffiliateSalePayoutModal';

const PartnerProgram = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [filter, setFilter] = useState('');
  const [affiliateLink, setAffiliateLink] = useState<string>();
  const [generatedAffiliateLink, setGeneratedAffiliateLink] = useState<
    string | undefined
  >();
  const [affiliateLinkError, setAffiliateLinkError] = useState(false);

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const getProfileAffiliateSalesOverviewQuery = useQuery({
    queryKey: ['affiliateSalesOverview'],
    queryFn: async () => {
      return await AffiliateSaleService.getUserAffiliateSalesOverview();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const getProfileAffiliateSalesQuery = useInfiniteQuery(
    ['affiliateSales', filter],
    // @ts-ignore
    AffiliateSaleService.getUserAffiliateSales,
    {
      getNextPageParam: (result) =>
        result?.affiliateSales?.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(user === undefined ||
          getProfileAffiliateSalesQuery.isLoading ||
          getProfileAffiliateSalesOverviewQuery.isLoading ||
          getProfileQuery.isLoading) && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>
            <div className="flex-1">
              <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                <div>
                  <h2 className="md:text-lg text-base font-semibold text-black">
                    Partner Program
                  </h2>
                  <p className="font-normal text-sm text-gray-500 leading-6">
                    Track your sales commission and generate partner links.
                  </p>
                </div>
              </div>

              <div>
                <div className="">
                  <div className="grid sm:grid-cols-4 gap-6 mt-4">
                    <div className="side-list-item p-4 box dark:bg-white/5">
                      <div className="flex-1">
                        <h4 className="side-list-title">Total</h4>
                        <div className="side-list-info text-sm mt-2">
                          {getProfileAffiliateSalesOverviewQuery?.data
                            ?.commissions?.TOTAL !== undefined
                            ? new Intl.NumberFormat(undefined, {
                                style: 'currency',
                                currency: 'USD',
                              }).format(
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.TOTAL,
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="side-list-item p-4 box dark:bg-white/5">
                      <div className="flex-1">
                        <h4 className="side-list-title">Paid</h4>
                        <div className="side-list-info text-sm mt-2">
                          {getProfileAffiliateSalesOverviewQuery?.data
                            ?.commissions?.[AffiliateSaleStatus.PAID] !==
                          undefined
                            ? new Intl.NumberFormat(undefined, {
                                style: 'currency',
                                currency: 'USD',
                              }).format(
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.[AffiliateSaleStatus.PAID],
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="side-list-item p-4 box dark:bg-white/5">
                      <div className="flex-1">
                        <h4 className="side-list-title">Unpaid</h4>
                        <div className="side-list-info text-sm mt-2">
                          {getProfileAffiliateSalesOverviewQuery?.data
                            ?.commissions?.[AffiliateSaleStatus.UNPAID] !==
                          undefined
                            ? new Intl.NumberFormat(undefined, {
                                style: 'currency',
                                currency: 'USD',
                              }).format(
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.[AffiliateSaleStatus.UNPAID],
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="side-list-item p-4 box dark:bg-white/5">
                      <div className="flex-1">
                        <h4 className="side-list-title">
                          Payout{' '}
                          <small className="font-normal">
                            (min. payout amount $25)
                          </small>
                        </h4>
                        <div className="side-list-info text-sm mt-2">
                          <button
                            disabled={
                              getProfileAffiliateSalesOverviewQuery?.data
                                ?.payoutRequested ||
                              !!(
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.UNPAID &&
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.UNPAID < 25
                              )
                            }
                            className={`button py-2 px-3 sm:py-2 sm:px-5 bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 w-full ${
                              getProfileAffiliateSalesOverviewQuery?.data
                                ?.payoutRequested ||
                              !!(
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.UNPAID &&
                                getProfileAffiliateSalesOverviewQuery?.data
                                  ?.commissions?.UNPAID < 25
                              )
                                ? 'disabled:bg-opacity-90 cursor-not-allowed'
                                : ''
                            }`}
                            data-uk-toggle="target: #request-affiliate-sale-payout"
                          >
                            Request Payout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-8 pb-10 border-b border-slate-100 dark:border-slate-700">
                  {getProfileQuery?.data?.referralId && (
                    <>
                      <label
                        className="text-sm font-semibold"
                        htmlFor="affiliateLink"
                      >
                        Generate Partner Link
                      </label>

                      <div className="flex items-center gap-6 mt-1">
                        <input
                          className="w-3/4 placeholder:italic"
                          id="affiliateLink"
                          type="text"
                          placeholder="https://store.playsum.live..."
                          defaultValue={affiliateLink}
                          onChange={(e) => {
                            setAffiliateLinkError(false);
                            setGeneratedAffiliateLink(undefined);
                            setAffiliateLink(e.target.value);
                          }}
                        />
                        <button
                          className="button py-2 px-3 sm:py-2.5 sm:px-5 bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 flex-1"
                          onClick={async () => {
                            setAffiliateLinkError(false);

                            if (
                              affiliateLink?.includes(
                                'https://store.playsum.live',
                              )
                            ) {
                              const url = new URL(affiliateLink);

                              url.searchParams.set(
                                'plysm_ref_id',
                                getProfileQuery?.data?.referralId as string,
                              );

                              setGeneratedAffiliateLink(url.toString());
                            } else {
                              setAffiliateLinkError(true);
                            }
                          }}
                        >
                          Generate link
                        </button>
                      </div>
                      {affiliateLinkError && (
                        <p className="text-red-500 text-xs italic mt-1.5">
                          The url needs to start with https://store.playsum.live
                        </p>
                      )}
                      {generatedAffiliateLink && (
                        <p className="text-sm italic mt-1.5">
                          {generatedAffiliateLink}
                        </p>
                      )}
                    </>
                  )}
                </div>

                <div className="sm:my-6 my-3 flex items-center justify-end mt-10 sm:mt-10 gap-4">
                  <div className="flex items-center gap-3 my-auto">
                    <p className="font-normal text-sm hidden sm:block">
                      Filter by
                    </p>
                    <select
                      className="w-44 dark:bg-slate-800"
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="PAID">Paid</option>
                      <option value="UNPAID">Unpaid</option>
                      <option value="PENDING">Pending</option>
                      <option value="CANCELED">Canceled</option>
                    </select>
                  </div>
                </div>

                {getProfileAffiliateSalesQuery?.data && (
                  <InfiniteScroll
                    dataLength={
                      getProfileAffiliateSalesQuery?.data
                        ? getProfileAffiliateSalesQuery?.data?.pages?.flatMap(
                            (page) => page.affiliateSales,
                          )?.length || 0
                        : 0
                    }
                    next={getProfileAffiliateSalesQuery.fetchNextPage}
                    hasMore={getProfileAffiliateSalesQuery.hasNextPage || false} // Replace with a condition based on your data source
                    loader={null}
                  >
                    <table className="table-auto w-full mb-10">
                      <thead className="border-b font-medium text-sm text-black dark:text-white">
                        <tr>
                          <th className="pt-2 pb-4 px-4 text-left">
                            Order Date
                          </th>
                          <th className="pt-2 pb-4 px-4 text-left">
                            Commission
                          </th>
                          <th className="pt-2 pb-4 px-4 text-left">Status</th>
                        </tr>
                      </thead>
                      <tbody className="font-normal text-sm text-black dark:text-white">
                        {getProfileAffiliateSalesQuery?.data?.pages.map(
                          (group, i) => (
                            <React.Fragment key={i}>
                              {group.affiliateSales.map(
                                (affiliateSale, idx) => (
                                  <tr key={idx} className="">
                                    <td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700">
                                      {new Date(
                                        affiliateSale.orderDate,
                                      ).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                      })}
                                    </td>
                                    <td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700">
                                      {affiliateSale.status !==
                                        AffiliateSaleStatus.PENDING &&
                                      affiliateSale?.commission ? (
                                        <>
                                          {new Intl.NumberFormat(undefined, {
                                            style: 'currency',
                                            currency: Currency.USD,
                                          }).format(affiliateSale.commission)}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </td>
                                    <td className="py-4 px-4 border-b border-slate-100 dark:border-slate-700">
                                      {t(
                                        `AffiliateSaleStatus.${affiliateSale.status}`,
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )}
                            </React.Fragment>
                          ),
                        )}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                )}

                <RequestAffiliateSalePayoutModal />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PartnerProgram;
