import Api from '../utils/api';
import { IPostComment } from '../interfaces/post-comment.interface';
import { PostReportReason } from '../enums/post-report-reason.enum';
import { IPost } from '../interfaces/post.interface';
import { IPostReply } from '../interfaces/post-reply.interface';

export const PostService = {
  getPost: async (postId: string): Promise<IPost> => {
    const { data } = await Api.get<IPost>(`/posts/${postId}`);

    return data;
  },
  getComments: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ postComments: IPostComment[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/posts/${queryKey[1]}/comments?offset=${pageParam}`;

    const { data } = await Api.get<IPostComment[]>(url);

    return { postComments: data, offset: pageParam };
  },
  addReaction: async function (
    postId: string,
    reaction: 'LIKE' = 'LIKE',
  ): Promise<void> {
    await Api.post(`/posts/${postId}/reaction`, { reaction: reaction });
  },
  deleteReaction: async function (
    postId: string,
    reaction: 'LIKE' = 'LIKE',
  ): Promise<void> {
    await Api.delete(`/posts/${postId}/reaction`, {
      data: { reaction: reaction },
    });
  },
  reportPost: async function (
    postId: string,
    reason: PostReportReason,
    additionalInformation?: string,
  ): Promise<void> {
    const payload = {
      postId: postId,
      reason: reason,
    } as Record<string, any>;

    if (additionalInformation) {
      payload.additionalInformation = additionalInformation;
    }

    await Api.post('/posts/report', payload);
  },
  addComment: async function (
    postId: string,
    content: string,
  ): Promise<IPostComment> {
    const { data } = await Api.post<IPostComment>(`/posts/${postId}/comments`, {
      content: content.trim(),
    });

    return data;
  },
  deletePost: async function (postId: string): Promise<void> {
    await Api.delete(`/posts/${postId}`);
  },
  deleteComment: async function (commentId: string): Promise<void> {
    await Api.delete(`/posts/comments/${commentId}`);
  },
  getPostReplies: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ postReplies: IPostReply[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/posts/replies/${queryKey[1]}?offset=${pageParam}`;

    const { data } = await Api.get<IPostReply[]>(url);

    return { postReplies: data, offset: pageParam };
  },
};
