import React from 'react';
import { IonIcon } from '@ionic/react';
import { flagOutline } from 'ionicons/icons';
import { IPost } from '../interfaces/post.interface';
import ReportPostModal from './ReportPostModal';

const PostReport = ({
  post,
  iconSize,
  classNames = 'button-icon ml-auto',
}: {
  post: IPost;
  iconSize: 'lg' | 'xl';
  classNames?: string;
}) => {
  return (
    <div>
      <button
        type="button"
        className={classNames}
        data-uk-toggle={`target: #report-post-${post.id}`}
      >
        <IonIcon icon={flagOutline} className={`text-${iconSize}`} />
      </button>

      <ReportPostModal post={post} targetId={`report-post-${post.id}`} />
    </div>
  );
};

export default PostReport;
