import Api from '../utils/api';

interface ChatTokenResponse {
  token: string;
}

interface UserChatResponse {
  channelId: string;
}

export const ChatService = {
  getToken: async function (): Promise<string> {
    const { data } = await Api.get<ChatTokenResponse>('/chat/user/token');

    return data.token;
  },
  getUserChat: async function (userId: string): Promise<UserChatResponse> {
    const { data } = await Api.get<UserChatResponse>(`/chat/user/${userId}`);

    return data;
  },
};
