import Api from '../utils/api';

export const UserFollowService = {
  followUser: async function (userId: string): Promise<void> {
    await Api.post('/user/follow', {
      followeeId: userId,
    });
  },
  unfollowUser: async function (userId: string): Promise<void> {
    await Api.delete(`/user/unfollow/${userId}`);
  },
};
