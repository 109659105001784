import React from 'react';
import Layout from '../components/Layout';
import { useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../components/AccountHeader';
import { OrderService } from '../services/OrderService';
import { LanguageCode } from '../enums/language-code.enum';
import { Link } from 'react-router-dom';
import { getImage } from '../utils/image';
import { useAuthStore } from '../store/zustand';

const AccountOrders = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const getOrdersQuery = useQuery({
    queryKey: ['orders'],
    queryFn: async () => {
      return await OrderService.getOrders(LanguageCode.EN);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(user === undefined || getOrdersQuery.isLoading) && <Loader />}

        {user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>

            <div className="flex-1 space-y-10">
              {getOrdersQuery?.data?.map((order, idx) => {
                return (
                  <div key={idx}>
                    <div className="sm:mb-6 mb-3 flex items-center justify-between">
                      <div>
                        <h2 className="text-lg font-semibold">
                          {t('Orders.orderNr')}: {order.orderId}
                        </h2>
                        <p className="font-normal text-sm text-gray-500 leading-6">
                          <span>
                            {new Date(order.orderDate).toLocaleDateString(
                              undefined,
                              {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              },
                            )}
                          </span>
                          <span className="mx-2">·</span>
                          <span className="capitalize">
                            {t(`PaymentStatus.${order.paymentStatus}`)}
                          </span>
                          <span className="mx-2">·</span>
                          <Link
                            to="https://help.playsum.live/support/tickets/new"
                            target={'_blank'}
                            className="text-[#db00ff]"
                          >
                            {t('Orders.reportProblem')}
                          </Link>
                        </p>
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-5 md:grid-cols-6 xl:grid-cols-7 grid-cols-2 gap-3">
                      {order.items.map((orderItem, idx) => (
                        <div key={idx} className="card">
                          <div className="card-media aspect-auto">
                            <img
                              className="!relative object-cover"
                              src={getImage(
                                orderItem.product?.coverImage,
                                'packshot',
                              )}
                              alt={`${orderItem.product.name} Cover`}
                            />
                          </div>
                          <div className="card-body">
                            <h4 className="card-title line-clamp-1">
                              {orderItem.product.name}
                            </h4>
                            <p className="card-text line-clamp-1">
                              {orderItem.product.platforms
                                .map((platform) =>
                                  // @ts-ignore
                                  t(`Platforms.${platform}`),
                                )
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AccountOrders;
