import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Formik } from 'formik';
import * as yup from 'yup';
import { POST_CONTENT_MAX_LENGTH } from '../constants';
import { IonIcon } from '@ionic/react';
import {
  chevronBack,
  chevronForward,
  image,
  timeOutline,
} from 'ionicons/icons';
import { FeedService } from '../services/FeedService';
import InfoToast from './InfoToast';
import ErrorToast from './ErrorToast';
import Loader from './Loader';

const CreatePostModal = ({ targetId }: { targetId: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const currentPostHashtagRef = useRef<HTMLInputElement>(null);

  const createPostMutation = useMutation(
    (formData: FormData) => {
      return FeedService.createPost(formData);
    },
    {
      onSuccess: () => {
        // @ts-ignore
        if (typeof window?.UIkit !== 'undefined') {
          // @ts-ignore
          UIkit.modal(`#${targetId}`).hide();
        }

        InfoToast('Post submitted successfully!');

        setTimeout(function () {
          void queryClient.invalidateQueries(['userTimeline']);
        }, 3000);
      },
      onError: (e: any) => {
        console.error(e);

        if (e?.response?.status === 400) {
          ErrorToast(t('Errors.postRequirements'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  return (
    <div id="create-post-modal-wrapper">
      <div
        className="hidden lg:p-20"
        id={targetId}
        data-uk-modal="container: #create-post-modal-wrapper;"
      >
        {createPostMutation.isLoading && <Loader />}

        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white p-7 shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2">
          <div className="text-center py-3 border-b -m-7 mb-0 dark:border-slate-700">
            <h2 className="text-sm font-medium">Create Post</h2>

            <button
              type="button"
              className="button__ico absolute top-0 right-0 m-2.5 uk-modal-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <Formik
            initialValues={{
              content: '',
              hashtags: [],
              attachments: [],
            }}
            validationSchema={yup.object({
              content: yup
                .string()
                .when('attachments', {
                  is: (value: any) => value?.length === 0,
                  then: (schema) =>
                    schema.required(
                      'Either post content or attachments need to be set',
                    ),
                })
                .max(
                  POST_CONTENT_MAX_LENGTH,
                  `Post content can not be longer than ${POST_CONTENT_MAX_LENGTH} characters`,
                )
                .trim(),
              hashtags: yup
                .array()
                .of(
                  yup
                    .string()
                    .min(2, 'Hashtag needs to be at least 2 characters')
                    .max(32, 'Hashtag can not be longer than 32 characters'),
                )
                .max(5, 'You can not add more than 5 hashtags'),
              attachments: yup
                .array()
                .of(yup.string())
                .max(3, 'You can not add more than 3 images'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const formData = new FormData();
              formData.append('content', values.content);

              for (const hashtag of values.hashtags) {
                formData.append('hashtags[]', hashtag);
              }

              for (const attachment of values.attachments) {
                formData.append('attachments', attachment);
              }

              createPostMutation.mutate(formData, {
                onSuccess: () => resetForm(),
              });

              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="space-y-5 mt-2">
                <div>
                  <textarea
                    id="content"
                    className="mt-3 w-full"
                    placeholder="What do you have in mind?"
                    rows={4}
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.content && (
                    <p className="text-red-500 text-xs italic mt-1">
                      {errors.content}
                    </p>
                  )}
                </div>

                <div>
                  {values.hashtags.length > 0 && (
                    <div className="mb-3">
                      {values.hashtags.map((hashtag, i) => (
                        <span
                          key={i}
                          className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium bg-[#db00ff] rounded text-white"
                        >
                          {hashtag}
                          <button
                            type="button"
                            className="inline-flex items-center p-1 ms-2 text-sm cursor-pointer"
                            onClick={async () => {
                              await setFieldValue(
                                'hashtags',
                                values.hashtags.filter(
                                  (tag) => tag !== hashtag,
                                ),
                              );
                            }}
                          >
                            <svg
                              className="w-2 h-2"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                          </button>
                        </span>
                      ))}
                    </div>
                  )}

                  <div className="flex space-x-3">
                    <input
                      type="text"
                      placeholder="Search / Create Hashtags"
                      id="hashtags"
                      ref={currentPostHashtagRef}
                      className="w-full"
                      onBeforeInput={(
                        e: React.CompositionEvent<HTMLInputElement>,
                      ) => {
                        const value = e.data;
                        if (!/^[a-z0-9]{0,32}$/.test(value.toLowerCase())) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase().trim();
                        if (
                          /^[a-z0-9]{0,32}$/.test(value) &&
                          currentPostHashtagRef?.current
                        ) {
                          currentPostHashtagRef.current.value = value;
                        }
                      }}
                      onKeyDown={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    />
                    <button
                      type="button"
                      className="button text-sm rounded-lg bg-secondery px-4"
                      onClick={async () => {
                        if (
                          currentPostHashtagRef?.current?.value &&
                          currentPostHashtagRef?.current?.value !== ''
                        ) {
                          await setFieldValue('hashtags', [
                            ...new Set([
                              ...values.hashtags,
                              currentPostHashtagRef.current.value,
                            ]),
                          ]);

                          currentPostHashtagRef.current.value = '';
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {errors.hashtags && (
                    <p className="text-red-500 text-xs italic mt-1">
                      {errors.hashtags}
                    </p>
                  )}
                </div>

                <div>
                  {values.attachments?.length > 0 && (
                    <div
                      className="relative uk-visible-toggle mb-3"
                      tabIndex={-1}
                      data-uk-slideshow="animation: push;ratio: 4:3"
                    >
                      <ul className="uk-slideshow-items overflow-hidden rounded-xl">
                        {values.attachments.map((attachment, idx) => (
                          <li key={idx} className="w-full">
                            <a className="inline">
                              <img
                                src={URL.createObjectURL(attachment)}
                                alt=""
                                className="w-full h-full absolute object-cover insta-0"
                              />
                            </a>
                            <button
                              type="button"
                              className="absolute top-2 right-2 bg-white dark:bg-dark2 rounded-full p-1.5"
                              onClick={async () => {
                                await setFieldValue(
                                  'attachments',
                                  values.attachments.filter(
                                    (_, index) => index !== idx,
                                  ),
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </li>
                        ))}
                      </ul>

                      <a
                        className="nav-prev left-6"
                        href="#"
                        data-uk-slideshow-item="previous"
                      >
                        <IonIcon icon={chevronBack} className="text-2xl" />
                      </a>
                      <a
                        className="nav-next right-6"
                        href="#"
                        data-uk-slideshow-item="next"
                      >
                        <IonIcon icon={chevronForward} className="text-2xl" />
                      </a>
                    </div>
                  )}

                  <div className="w-full h-28 relative border1 rounded-lg overflow-hidden bg-repeat">
                    <label
                      htmlFor="attachments"
                      className="flex flex-col justify-center items-center absolute -translate-x-1/2 left-1/2 bottom-0 z-10 w-full pb-6 pt-10 cursor-pointer bg-gradient-to-t from-gray-700/60"
                    >
                      <input
                        id="attachments"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                        className="hidden"
                        onChange={async (event) => {
                          const files = [];

                          if (event?.currentTarget?.files) {
                            for (const file of event.currentTarget.files) {
                              files.push(file);
                            }
                          }

                          await setFieldValue('attachments', files);
                        }}
                      />
                      <IonIcon
                        icon={image}
                        className="text-3xl text-teal-600"
                      />

                      <span className="text-white mt-2">
                        Browse to Upload image
                      </span>
                    </label>

                    <img
                      id="createStatusImage"
                      src="#"
                      alt="Uploaded Image"
                      style={{ display: 'none' }}
                      className="w-full h-full absolute object-cover"
                    />
                  </div>
                  {errors.attachments && (
                    <p className="text-red-500 text-xs italic mt-1">
                      {errors.attachments}
                    </p>
                  )}
                </div>

                <div className="flex justify-between items-center">
                  <div className="flex items-start gap-2">
                    <IonIcon
                      icon={timeOutline}
                      className="text-3xl text-[#db00ff]"
                    />
                    <p className="text-sm text-gray-500 font-medium">
                      Your Post will be available normally <br />
                      within a couple of minutes
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="button text-sm rounded-lg py-1.5 bg-secondery px-8"
                    disabled={isSubmitting}
                  >
                    Create
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreatePostModal;
