import React, { useState } from 'react';
import Layout from '../components/Layout';
import { IProfile } from '../interfaces/profile.interface';
import { useMutation, useQuery } from 'react-query';
import { UserService } from '../services/UserService';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';
import { Pronoun } from '../enums/pronoun.enum';
import * as yup from 'yup';
import { Formik } from 'formik';
import AccountHeader from '../components/AccountHeader';
import { useAuthStore } from '../store/zustand';

const Account = () => {
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [profile, setProfile] = useState<IProfile>();

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      setProfile(profile);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const updateProfileMutation = useMutation(
    (updateData: {
      pronoun?: Pronoun;
      birthday?: string;
      bio?: string;
      socialHandles?: {
        xbox?: string;
        psn?: string;
        steam?: string;
        twitch?: string;
        youtube?: string;
      };
      notificationSettings?: {
        wishlistItemDiscountEmail?: boolean;
      };
    }) => {
      return UserService.updateProfile(updateData);
    },
    {
      onSuccess: async (profile) => {
        setProfile(profile);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto pb-5 sm:pb-10">
        {(getProfileQuery.isLoading || updateProfileMutation.isLoading) && (
          <Loader />
        )}

        {profile && user && (
          <>
            <AccountHeader user={user} />

            <div
              className="flex 2xl:gap-12 gap-6 mt-6 max-lg:flex-col"
              id="js-oversized"
            ></div>

            <div className="flex-1">
              <div className="sm:mb-6 mb-3 flex items-center justify-between gap-4">
                <div>
                  <h2 className="md:text-lg text-base font-semibold text-black">
                    Settings
                  </h2>
                  <p className="font-normal text-sm text-gray-500 leading-6">
                    Update your account settings.
                  </p>
                </div>
              </div>

              <div className="box relative rounded-lg shadow-md">
                <div className="md:py-12 md:px-20 p-6 overflow-hidden text-black dark:text-white text-sm">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      pronoun: profile?.pronoun,
                      birthday: profile?.birthday ?? '',
                      bio: profile?.bio ?? '',
                      socialHandles: profile?.socialHandles,
                      notificationSettings: profile?.notificationSettings,
                    }}
                    validationSchema={yup.object({
                      pronoun: yup
                        .string()
                        .oneOf(
                          Object.values(Pronoun),
                          t('FormValidations.pronoun'),
                        )
                        .trim(),
                      birthday: yup
                        .date()
                        .test(
                          'birthday',
                          t('FormValidations.birthdayMinAge'),
                          function (value) {
                            if (!value) return false;

                            const cutOffDate = new Date();
                            cutOffDate.setFullYear(
                              cutOffDate.getFullYear() - 13,
                            );
                            return value <= cutOffDate;
                          },
                        )
                        .required(t('FormValidations.birthdayRequired')),
                      bio: yup
                        .string()
                        .max(1000, t('FormValidations.bio'))
                        .trim(),
                      socialHandles: yup.object().shape({
                        xbox: yup
                          .string()
                          .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                            message: t('FormValidations.socialHandles.generic'),
                          })
                          .trim(),
                        psn: yup
                          .string()
                          .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                            message: t('FormValidations.socialHandles.generic'),
                          })
                          .trim(),
                        steam: yup
                          .string()
                          .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                            message: t('FormValidations.socialHandles.generic'),
                          })
                          .trim(),
                        twitch: yup
                          .string()
                          .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                            message: t('FormValidations.socialHandles.generic'),
                          })
                          .trim(),
                        youtube: yup
                          .string()
                          .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                            message: t('FormValidations.socialHandles.generic'),
                          })
                          .matches(/^@.*$/, {
                            message: t('FormValidations.socialHandles.youtube'),
                          })
                          .trim(),
                      }),
                      notificationSettings: yup.object().shape({
                        wishlistItemDiscountEmail: yup.boolean(),
                      }),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      updateProfileMutation.mutate(values);

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="space-y-8">
                            <div className="space-y-6">
                              <h4 className="text-base font-medium mb-1.5 text-black dark:text-white">
                                {t('FormLabels.general')}
                              </h4>
                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('FormLabels.pronoun')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <select
                                    id="pronoun"
                                    value={values.pronoun}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="!border-0 !rounded-md w-full"
                                  >
                                    <option value="invalid"></option>
                                    {Object.keys(Pronoun).map((pronoun) => {
                                      return (
                                        <option key={pronoun} value={pronoun}>
                                          {/*@ts-ignore*/}
                                          {t(`Pronouns.${pronoun}`)}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  {errors.pronoun && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.pronoun}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('FormLabels.birthday')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id="birthday"
                                    type="date"
                                    className="w-full"
                                    value={values.birthday}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />

                                  {errors.birthday && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.birthday}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-start gap-10">
                                <label className="md:w-32 text-right">
                                  {t('FormLabels.bio')}
                                </label>

                                <div className="flex-1 max-md:mt-4">
                                  <textarea
                                    id="bio"
                                    className="w-full"
                                    rows={5}
                                    value={values.bio}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  ></textarea>

                                  {errors.bio && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.bio}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="space-y-6">
                              <h4 className="text-base font-medium mb-1.5 text-black dark:text-white">
                                {t('FormLabels.socialHandles')}
                              </h4>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('SocialHandles.xbox')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id={'socialHandles.xbox'}
                                    value={values.socialHandles?.xbox}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className="w-full"
                                  />

                                  {errors.socialHandles?.xbox && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.socialHandles?.xbox}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('SocialHandles.psn')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id={'socialHandles.psn'}
                                    value={values.socialHandles?.psn}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className="w-full"
                                  />

                                  {errors.socialHandles?.psn && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.socialHandles?.psn}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('SocialHandles.steam')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id={'socialHandles.steam'}
                                    value={values.socialHandles?.steam}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className="w-full"
                                  />

                                  {errors.socialHandles?.steam && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.socialHandles?.steam}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('SocialHandles.twitch')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id={'socialHandles.twitch'}
                                    value={values.socialHandles?.twitch}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className="w-full"
                                  />

                                  {errors.socialHandles?.twitch && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.socialHandles?.twitch}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="md:flex items-center gap-10">
                                <label className="md:w-32 text-right">
                                  {t('SocialHandles.youtube')}
                                </label>
                                <div className="flex-1 max-md:mt-4">
                                  <input
                                    id={'socialHandles.youtube'}
                                    value={values.socialHandles?.youtube}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    className="w-full"
                                  />

                                  {errors.socialHandles?.youtube && (
                                    <p className="text-red-500 text-xs italic mt-1">
                                      {errors.socialHandles?.youtube}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="space-y-6">
                              <h4 className="text-base font-medium mb-1.5 text-black dark:text-white">
                                {t('FormLabels.notifications')}
                              </h4>

                              <div className="max-w-xl mx-auto font-normal text-gray-400 text-sm">
                                <div className="space-y-6">
                                  <div className="w-full">
                                    <label className="switch flex justify-between items-center cursor-pointer gap-4">
                                      <div className="flex-1 md:pr-8">
                                        <h4 className="text-base font-medium mb-1.5 text-black dark:text-white">
                                          Email
                                        </h4>
                                        <p className="">
                                          Receive an email notification when one
                                          of your wishlist items goes on sale.
                                        </p>
                                      </div>

                                      <input
                                        id="notificationSettings.wishlistItemDiscountEmail"
                                        type="checkbox"
                                        defaultChecked={
                                          values.notificationSettings
                                            ?.wishlistItemDiscountEmail
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />

                                      <span className="switch-button !relative"></span>

                                      {errors.notificationSettings
                                        ?.wishlistItemDiscountEmail && (
                                        <p className="text-red-500 text-xs italic mt-1">
                                          {
                                            errors.notificationSettings
                                              ?.wishlistItemDiscountEmail
                                          }
                                        </p>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-4 mt-16 md:pl-[10.5rem]">
                            <button
                              type="submit"
                              className="button lg:px-10 text-sm rounded-lg py-1.5 bg-secondery md:w-auto w-full"
                              disabled={isSubmitting}
                            >
                              Save<span className="ripple-overlay"></span>
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Account;
