import Api from '../utils/api';
import { jwtDecode } from 'jwt-decode';
import { IAuthUser } from '../interfaces/auth-user.interface';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { IProfile } from '../interfaces/profile.interface';
import { Pronoun } from '../enums/pronoun.enum';
import { Platform } from '../enums/platform.enum';
import { IUser } from '../interfaces/user.interface';
import { ReportReason } from '../enums/report-reason.enum';
import { IUserGame } from '../interfaces/user-game.interface';

export const UserService = {
  login: async function (
    email: string,
    password: string,
  ): Promise<{
    user: IAuthUser;
  }> {
    const { data } = await Api.post<{
      accessToken: string;
      refreshToken: string;
    }>(`/auth/login`, {
      email: email,
      password: password,
    });

    const decodedToken = jwtDecode<Record<string, any>>(data?.accessToken);

    if (!decodedToken?.sub || !decodedToken?.username || !decodedToken?.roles) {
      throw new Error('Invalid access token');
    }

    return {
      user: {
        id: decodedToken.sub,
        username: decodedToken?.username,
        email: decodedToken?.email,
        roles: decodedToken?.roles,
      },
    };
  },
  register: async function (
    email: string,
    username: string,
    birthday: string,
    password: string,
    redirectTo?: string,
  ): Promise<void> {
    const payload: {
      email: string;
      username: string;
      birthday: string;
      password: string;
      redirectTo?: string;
    } = {
      email: email,
      username: username,
      birthday: birthday,
      password: password,
    };

    if (redirectTo) {
      payload.redirectTo = redirectTo;
    }

    await Api.post<{
      id: string;
    }>(`/auth/register`, payload);
  },
  forgotPassword: async function (
    email: string,
    redirectTo?: string,
  ): Promise<void> {
    const payload: {
      email: string;
      redirectTo?: string;
    } = {
      email: email,
    };

    if (redirectTo) {
      payload.redirectTo = redirectTo;
    }

    await Api.post('/auth/forgot-password', payload);
  },
  resetPassword: async function (
    token: string,
    password: string,
  ): Promise<void> {
    const { data } = await Api.post<{ successful: boolean }>(
      '/auth/reset-password',
      {
        token: token,
        password: password,
      },
    );

    if (!data.successful) {
      throw new Error();
    }
  },
  resendVerification: async function (
    email: string,
    redirectTo?: string,
  ): Promise<void> {
    const payload: { email: string; redirectTo?: string } = { email: email };

    if (redirectTo) {
      payload.redirectTo = redirectTo;
    }

    await Api.post('/auth/resend-verification', payload);
  },
  verifyAccount: async function (token: string): Promise<void> {
    await Api.post('/auth/verify', { token: token });
  },
  refresh: async function (): Promise<{
    user: IAuthUser;
  }> {
    const { data } = await Api.post<{
      accessToken: string;
      refreshToken: string;
    }>('/auth/refresh', undefined, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);

    const decodedToken = jwtDecode<Record<string, any>>(data?.accessToken);

    if (!decodedToken?.sub || !decodedToken?.username || !decodedToken?.roles) {
      throw new Error('Invalid access token');
    }

    return {
      user: {
        id: decodedToken.sub,
        username: decodedToken?.username,
        email: decodedToken?.email,
        roles: decodedToken?.roles,
      },
    };
  },
  logout: async function (): Promise<void> {
    await Api.post(`/auth/logout`);
  },
  getProfile: async function (): Promise<IProfile> {
    const { data } = await Api.get<IProfile>('/user/profile');

    return data;
  },
  updateProfile: async function (updateData: {
    pronoun?: Pronoun;
    birthday?: string;
    bio?: string;
    games?: { gameId: string; platforms: Platform[] }[];
    socialHandles?: {
      xbox?: string;
      psn?: string;
      steam?: string;
      twitch?: string;
      youtube?: string;
    };
    notificationSettings?: {
      wishlistItemDiscountEmail?: boolean;
    };
  }): Promise<IProfile> {
    const { data } = await Api.patch<IProfile>('/user/profile', updateData);

    return data;
  },
  addAvatar: async function (formData: FormData) {
    await Api.post<void>('/user/profile/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteAccount: async function (): Promise<void> {
    await Api.post('/user/profile/delete', undefined);
  },
  searchUsers: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ users: IUser[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    let url = `/user/search?offset=${pageParam}`;

    if (queryKey.length === 2) {
      const filter = queryKey[1];

      if (filter !== '') {
        url += `&filter=${filter}`;
      }
    }

    const { data } = await Api.get<IUser[]>(url);

    return { users: data, offset: pageParam };
  },
  getUser: async function (userId: string): Promise<IUser> {
    const { data } = await Api.get<IUser>(`/user/${userId}/profile`);

    return data;
  },
  reportUser: async function (
    reportedUserId: string,
    reason: ReportReason,
    additionalInformation?: string,
  ): Promise<void> {
    const payload = {
      userId: reportedUserId,
      reason: reason,
    } as Record<string, any>;

    if (additionalInformation) {
      payload.additionalInformation = additionalInformation;
    }

    await Api.post('/user/report', payload);
  },
  blockUser: async function (userId: string): Promise<void> {
    await Api.post('/user/block', {
      userId: userId,
    });
  },
  getUserGames: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ userGames: IUserGame[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/user/${queryKey[1]}/games?offset=${pageParam}`;

    const { data } = await Api.get<IUserGame[]>(url);

    return { userGames: data, offset: pageParam };
  },
  getSimilarUsers: async function (): Promise<IUser[]> {
    const { data } = await Api.get<IUser[]>(`/user/similar`);

    return data;
  },
};
