import React, { useState } from 'react';
import { IFriendship } from '../interfaces/friendship.interface';
import Avatar from './Avatar';
import {
  checkmarkOutline,
  closeOutline,
  ellipsisHorizontal,
  syncOutline,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import AcceptFriendRequestModal from './AcceptFriendRequestModal';
import RejectFriendRequestModal from './RejectFriendRequestModal';
import { Link } from 'react-router-dom';
import RemoveFriendRequestModal from './RemoveFriendRequestModal';
import { useQuery } from 'react-query';
import { FriendshipService } from '../services/FriendshipService';
import ErrorToast from './ErrorToast';
import { useTranslation } from 'react-i18next';

const FriendsSidebar = () => {
  const { t } = useTranslation();

  const [expandReceivedFriends, setExpandReceivedFriends] = useState(false);
  const [expandRequestedFriends, setExpandRequestedFriends] = useState(false);

  const receivedFriendshipsToShow = 5;
  const requestedFriendshipsToShow = 5;

  const getReceivedFriendshipsQuery = useQuery({
    queryKey: ['receivedFriendships'],
    queryFn: async () => {
      return await FriendshipService.getReceivedFriendRequests();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const getRequestedFriendshipsQuery = useQuery({
    queryKey: ['requestedFriendships'],
    queryFn: async () => {
      return await FriendshipService.getRequestedFriendRequests();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const ReceivedFriendshipItem = ({
    friendship,
  }: {
    friendship: IFriendship;
  }) => {
    return (
      <div>
        <div className="side-list-item">
          <Link to={`/user/${friendship.user.id}`}>
            <div className="side-list-image rounded-full">
              <Avatar
                userId={friendship.user.id}
                username={friendship.user.username}
              />
            </div>
          </Link>
          <div className="flex-1">
            <Link to={`/user/${friendship.user.id}`}>
              <h4 className="side-list-title">{friendship.user.username}</h4>
            </Link>
          </div>

          <div className="md:flex gap-x-2 hidden">
            <button
              className="rounded-lg pt-1.5 pb-0 px-4 bg-secondery"
              data-uk-toggle={`target: #reject-friendship-${friendship.id}`}
            >
              <IonIcon icon={closeOutline} className="text-lg" />
            </button>
            <button
              className="rounded-lg pt-1.5 pb-0 px-4 bg-secondery"
              data-uk-toggle={`target: #accept-friendship-${friendship.id}`}
            >
              <IonIcon icon={checkmarkOutline} className="text-lg" />
            </button>
          </div>

          <div className="md:hidden flex">
            <button className="rounded-lg pt-1.5 pb-0 px-4 bg-secondery">
              <IonIcon icon={ellipsisHorizontal} className="text-lg" />
            </button>

            <div
              className="uk-dropdown-close"
              data-uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click;offset:10"
            >
              <nav>
                <a
                  className="cursor-pointer"
                  data-uk-toggle={`target: #reject-friendship-${friendship.id}`}
                >
                  <IonIcon icon={closeOutline} className="text-lg" />
                  Reject
                </a>

                <a
                  className="cursor-pointer"
                  data-uk-toggle={`target: #accept-friendship-${friendship.id}`}
                >
                  <IonIcon icon={checkmarkOutline} className="text-xl" />
                  Accept
                </a>
              </nav>
            </div>
          </div>
        </div>

        <RejectFriendRequestModal
          friendship={friendship}
          targetId={`reject-friendship-${friendship.id}`}
        />

        <AcceptFriendRequestModal
          friendship={friendship}
          targetId={`accept-friendship-${friendship.id}`}
        />
      </div>
    );
  };

  const RequestedFriendshipItem = ({
    friendship,
  }: {
    friendship: IFriendship;
  }) => {
    return (
      <div>
        <div className="side-list-item">
          <Link to={`/user/${friendship.user.id}`}>
            <div className="side-list-image rounded-full">
              <Avatar
                userId={friendship.user.id}
                username={friendship.user.username}
              />
            </div>
          </Link>
          <div className="flex-1">
            <Link to={`/user/${friendship.user.id}`}>
              <h4 className="side-list-title">{friendship.user.username}</h4>
            </Link>
          </div>

          <div className="md:flex">
            <button
              className="rounded-lg pt-1.5 pb-0 px-4 bg-secondery"
              data-uk-toggle={`target: #remove-friendship-${friendship.id}`}
            >
              <IonIcon icon={closeOutline} className="text-lg" />
            </button>
          </div>
        </div>

        <RemoveFriendRequestModal
          friendship={friendship}
          targetId={`remove-friendship-${friendship.id}`}
        />
      </div>
    );
  };

  return (
    <div
      className="flex lg:pb-8 max-lg:grid sm:grid-cols-2 gap-3 sm:gap-6 flex-col"
      data-uk-sticky="media: 1024; end: #js-oversized; offset: 80"
    >
      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">Friend Requests</h3>
            <p className="font-normal text-sm text-gray-500 leading-6">
              {getReceivedFriendshipsQuery?.data && (
                <>
                  You received {getReceivedFriendshipsQuery?.data?.length}{' '}
                  friend requests.
                </>
              )}
            </p>
          </div>

          {getReceivedFriendshipsQuery.isLoading ? (
            <IonIcon
              icon={syncOutline}
              className={`text-xl ${getReceivedFriendshipsQuery.isLoading ? 'spin text-[#db00ff]' : ''}`}
            />
          ) : (
            <>
              {getReceivedFriendshipsQuery?.data &&
                getReceivedFriendshipsQuery?.data?.length > 0 && (
                  <div
                    className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
                    onClick={() => {
                      setExpandReceivedFriends(!expandReceivedFriends);
                    }}
                  >
                    {expandReceivedFriends ? 'Hide' : 'Show'}
                  </div>
                )}

              {getReceivedFriendshipsQuery?.data &&
                getReceivedFriendshipsQuery?.data?.length >
                  receivedFriendshipsToShow && (
                  <div
                    className="hidden sm:block text-sm text-[#db00ff] cursor-pointer"
                    onClick={() => {
                      setExpandReceivedFriends(!expandReceivedFriends);
                    }}
                  >
                    {expandReceivedFriends ? 'Show less' : 'Show all'}
                  </div>
                )}
            </>
          )}
        </div>

        {getReceivedFriendshipsQuery?.data &&
          getReceivedFriendshipsQuery?.data?.length > 0 && (
            <>
              {expandReceivedFriends && (
                <div className="sm:hidden side-list">
                  {getReceivedFriendshipsQuery?.data.map((friendship, idx) => (
                    <ReceivedFriendshipItem key={idx} friendship={friendship} />
                  ))}
                </div>
              )}

              {getReceivedFriendshipsQuery?.data?.length > 0 && (
                <div className="hidden sm:block side-list">
                  {(expandReceivedFriends
                    ? getReceivedFriendshipsQuery?.data
                    : getReceivedFriendshipsQuery?.data.slice(
                        0,
                        receivedFriendshipsToShow,
                      )
                  ).map((friendship, idx) => (
                    <ReceivedFriendshipItem key={idx} friendship={friendship} />
                  ))}
                </div>
              )}
            </>
          )}
      </div>

      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">Sent Friend Requests</h3>
            <p className="font-normal text-sm text-gray-500 leading-6">
              {getRequestedFriendshipsQuery?.data && (
                <>
                  You sent {getRequestedFriendshipsQuery?.data?.length} friend
                  requests.
                </>
              )}
            </p>
          </div>

          {getRequestedFriendshipsQuery.isLoading ? (
            <IonIcon
              icon={syncOutline}
              className={`text-xl ${getRequestedFriendshipsQuery.isLoading ? 'spin text-[#db00ff]' : ''}`}
            />
          ) : (
            <>
              {getRequestedFriendshipsQuery?.data &&
                getRequestedFriendshipsQuery?.data?.length > 0 && (
                  <div
                    className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
                    onClick={() => {
                      setExpandRequestedFriends(!expandRequestedFriends);
                    }}
                  ></div>
                )}

              {getRequestedFriendshipsQuery?.data &&
                getRequestedFriendshipsQuery?.data?.length >
                  requestedFriendshipsToShow && (
                  <div
                    className="hidden sm:block text-sm text-[#db00ff] cursor-pointer"
                    onClick={() => {
                      setExpandRequestedFriends(!expandRequestedFriends);
                    }}
                  >
                    {expandRequestedFriends ? 'Show less' : 'Show all'}
                  </div>
                )}
            </>
          )}
        </div>

        {getRequestedFriendshipsQuery?.data &&
          getRequestedFriendshipsQuery?.data?.length > 0 && (
            <>
              {expandRequestedFriends && (
                <div className="sm:hidden side-list">
                  {getRequestedFriendshipsQuery?.data?.map(
                    (friendship, idx) => (
                      <RequestedFriendshipItem
                        key={idx}
                        friendship={friendship}
                      />
                    ),
                  )}
                </div>
              )}

              {getRequestedFriendshipsQuery?.data?.length > 0 && (
                <div className="hidden sm:block side-list">
                  {(expandRequestedFriends
                    ? getRequestedFriendshipsQuery?.data
                    : getRequestedFriendshipsQuery?.data?.slice(
                        0,
                        requestedFriendshipsToShow,
                      )
                  ).map((friendship, idx) => (
                    <RequestedFriendshipItem
                      key={idx}
                      friendship={friendship}
                    />
                  ))}
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default FriendsSidebar;
