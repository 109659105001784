import React from 'react';
import { IPost } from '../interfaces/post.interface';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import { IonIcon } from '@ionic/react';
import {
  chatbubbleEllipses,
  chevronBack,
  chevronForward,
  ellipsisHorizontal,
  removeCircleOutline,
} from 'ionicons/icons';
import Linkify from 'linkify-react';
import { getImage } from '../utils/image';
import { IAuthUser } from '../interfaces/auth-user.interface';
import { timeAgo } from '../utils/time';
import PostReaction from './PostReaction';
import PostShare from './PostShare';
import { usePost } from '../hooks/usePost';
import PostReport from './PostReport';
import DeletePostModal from './DeletePostModal';

const Post = ({
  post,
  currentUser,
}: {
  currentUser: IAuthUser;
  post: IPost;
}) => {
  const { selectPost } = usePost();

  return (
    <div className="bg-white rounded-lg shadow-sm text-sm font-medium border1 dark:bg-dark2">
      <div className="flex gap-3 sm:p-4 p-2.5 sm:pb-2.5 text-sm font-medium">
        <Link to={`/user/${post.user.id}`}>
          <div className="w-9 h-9">
            <Avatar userId={post.user.id} username={post.user.username} />
          </div>
        </Link>
        <div className="flex-1">
          <Link to={`/user/${post.user.id}`}>
            <h4 className="text-black dark:text-white">{post.user.username}</h4>
          </Link>
          <div className="text-xs text-gray-500 dark:text-white/80">
            {timeAgo(new Date(post.createdAt), 'en')}
          </div>
        </div>

        {post.user.id === currentUser.id && (
          <div className="-mr-1">
            <button type="button" className="button-icon w-8 h-8">
              <IonIcon icon={ellipsisHorizontal} className="text-xl" />
            </button>
            <div
              className="w-[245px] uk-dropdown-close"
              data-uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click"
            >
              <nav>
                <a
                  href="#"
                  className="text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                  data-uk-toggle={`target: #delete-post-${post.id}`}
                >
                  <IonIcon
                    icon={removeCircleOutline}
                    className="text-xl shrink-0"
                  />
                  Delete Post
                </a>
              </nav>
            </div>

            <DeletePostModal post={post} targetId={`delete-post-${post.id}`} />
          </div>
        )}
      </div>

      {post.content !== '' && (
        <div className="sm:px-4 p-2.5 pb-0 pt-2.5">
          <p
            onClick={() => {
              selectPost(post.id);
            }}
            className="font-normal cursor-pointer whitespace-pre-line"
          >
            <Linkify
              options={{
                className: 'text-[#db00ff]',
                target: '_blank',
              }}
            >
              {post.content}
            </Linkify>
          </p>
        </div>
      )}

      {post?.hashtags?.length > 0 && (
        <div className="sm:px-4 p-2.5 pb-0">
          <p className="font-normal">
            {post?.hashtags?.map((hashtag, idx) => (
              <span key={idx} className="mr-2 text-[#db00ff]">
                #{hashtag.id}
              </span>
            ))}
          </p>
        </div>
      )}

      {post?.attachments?.length > 0 && (
        <div
          className="relative uk-visible-toggle sm:px-4 p-2.5 pb-0"
          tabIndex={-1}
          data-uk-slideshow="animation: push;ratio: 4:3"
        >
          <ul className="uk-slideshow-items overflow-hidden rounded-xl">
            {post.attachments.map((attachment, idx) => (
              <li key={idx} className="w-full">
                <img
                  onClick={() => {
                    selectPost(post.id);
                  }}
                  src={getImage(attachment.path)}
                  alt=""
                  className="w-full h-full absolute object-cover insta-0"
                />
              </li>
            ))}
          </ul>

          <a
            className="nav-prev left-6"
            href="#"
            data-uk-slideshow-item="previous"
          >
            <IonIcon icon={chevronBack} className="text-2xl" />
          </a>
          <a
            className="nav-next right-6"
            href="#"
            data-uk-slideshow-item="next"
          >
            <IonIcon icon={chevronForward} className="text-2xl" />
          </a>
        </div>
      )}

      <div className="sm:p-4 p-2.5 flex items-center gap-4 text-xs font-semibold">
        <div>
          <div className="flex items-center gap-2.5">
            <PostReaction post={post} />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="button-icon bg-slate-200/70 dark:bg-slate-700 cursor-pointer"
            onClick={() => {
              selectPost(post.id);
            }}
          >
            <IonIcon icon={chatbubbleEllipses} className="text-xl" />
          </button>

          <span>{post.commentCount}</span>
        </div>

        <PostShare post={post} iconSize="xl" />

        <PostReport post={post} iconSize="xl" classNames="button-icon" />
      </div>
    </div>
  );
};

export default Post;
