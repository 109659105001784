import React, { useState } from 'react';

import { IonIcon } from '@ionic/react';
import {
  accessibilityOutline,
  calendarClearOutline,
  timeOutline,
} from 'ionicons/icons';
import { IUser } from '../interfaces/user.interface';
import {
  FaPlaystation,
  FaSteam,
  FaTwitch,
  FaXbox,
  FaYoutube,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { timeAgo } from '../utils/time';

const UserSidebar = ({ profileUser }: { profileUser: IUser }) => {
  const { t } = useTranslation();

  const [expandAbout, setExpandAbout] = useState(false);

  const [expandSocials, setExpandSocials] = useState(false);

  const AboutMe = () => {
    return (
      <>
        {profileUser?.pronoun && (
          <li className="flex items-center gap-3">
            <IonIcon icon={accessibilityOutline} className="text-xl" />

            <div>
              Pronoun -{' '}
              <span className="font-semibold text-black dark:text-white">
                {/*@ts-ignore*/}
                {t(`Pronouns.${profileUser.pronoun}`)}
              </span>
            </div>
          </li>
        )}
        <li className="flex items-center gap-3">
          <IonIcon icon={calendarClearOutline} className="text-xl" />
          <div>
            Member since -{' '}
            <span className="font-semibold text-black dark:text-white">
              {new Date(profileUser.memberSince).toLocaleDateString()}
            </span>
          </div>
        </li>
        <li className="flex items-center gap-3">
          <IonIcon icon={timeOutline} className="text-xl" />
          <div>
            Last active -{' '}
            <span className="font-semibold text-black dark:text-white">
              {timeAgo(new Date(profileUser.lastActiveAt))}
            </span>
          </div>
        </li>
      </>
    );
  };

  const Socials = () => {
    return (
      <>
        {profileUser.socialHandles?.xbox && (
          <li className="flex items-center gap-3">
            <FaXbox className="text-lg" />
            <div>
              Xbox -{' '}
              <span className="font-semibold text-black dark:text-white">
                {profileUser.socialHandles?.xbox}
              </span>
            </div>
          </li>
        )}

        {profileUser.socialHandles?.psn && (
          <li className="flex items-center gap-3">
            <FaPlaystation className="text-lg" />
            <div>
              PSN -{' '}
              <span className="font-semibold text-black dark:text-white">
                {profileUser.socialHandles?.psn}
              </span>
            </div>
          </li>
        )}

        {profileUser.socialHandles?.steam && (
          <li className="flex items-center gap-3">
            <FaSteam className="text-lg" />
            <div>
              Steam -{' '}
              <span className="font-semibold text-black dark:text-white">
                {profileUser.socialHandles?.steam}
              </span>
            </div>
          </li>
        )}

        {profileUser.socialHandles?.twitch && (
          <li className="flex items-center gap-3">
            <FaTwitch className="text-lg" />
            <div>
              Twitch -{' '}
              <span className="font-semibold text-black dark:text-white">
                {profileUser.socialHandles?.twitch}
              </span>
            </div>
          </li>
        )}

        {profileUser.socialHandles?.youtube && (
          <li className="flex items-center gap-3">
            <FaYoutube className="text-lg" />
            <div>
              Youtube -{' '}
              <span className="font-semibold text-black dark:text-white">
                {profileUser.socialHandles?.youtube}
              </span>
            </div>
          </li>
        )}
      </>
    );
  };

  return (
    <div
      className="flex lg:pb-8 max-lg:grid sm:grid-cols-2 gap-3 sm:gap-6 flex-col"
      data-uk-sticky="media: 1024; end: #js-oversized; offset: 80"
    >
      <div className="box p-5 px-6">
        <div className="flex justify-between text-black dark:text-white">
          <div>
            <h3 className="font-bold text-base">About</h3>
          </div>

          <div
            className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
            onClick={() => {
              setExpandAbout(!expandAbout);
            }}
          >
            {expandAbout ? 'Hide' : 'Show'}
          </div>
        </div>

        {expandAbout && (
          <div className="sm:hidden text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
            <AboutMe />
          </div>
        )}

        <div className="hidden sm:block text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
          <AboutMe />
        </div>
      </div>

      {(profileUser.socialHandles?.xbox ||
        profileUser.socialHandles?.psn ||
        profileUser.socialHandles?.steam ||
        profileUser.socialHandles?.twitch ||
        profileUser.socialHandles?.youtube) && (
        <div className="box p-5 px-6">
          <div className="flex justify-between text-black dark:text-white">
            <div>
              <h3 className="font-bold text-base">Socials</h3>
            </div>

            <div
              className="sm:hidden text-sm text-[#db00ff] cursor-pointer"
              onClick={() => {
                setExpandSocials(!expandSocials);
              }}
            >
              {expandSocials ? 'Hide' : 'Show'}
            </div>
          </div>

          {expandSocials && (
            <div className="sm:hidden text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
              <Socials />
            </div>
          )}

          <div className="hidden sm:block text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
            <Socials />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSidebar;
