import React from 'react';
import { minidenticon } from 'minidenticons';
import { getAvatar } from '../utils/image';
import { Img } from 'react-image';

const Avatar = ({
  userId,
  username,
  className,
}: {
  userId: string;
  username?: string;
  className?: string;
}) => {
  return (
    <Img
      className={`rounded-full w-full h-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400 ${className}`}
      src={[
        getAvatar(userId),
        'data:image/svg+xml;utf8,' +
          encodeURIComponent(minidenticon(username || '')),
      ]}
      alt={`${username} Avatar`}
      loader={
        <img
          src={
            'data:image/svg+xml;utf8,' +
            encodeURIComponent(minidenticon(username || ''))
          }
          alt={`${username} Avatar`}
          className={`rounded-full w-full h-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400 ${className}`}
        />
      }
    />
  );
};

export default Avatar;
