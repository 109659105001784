import Api from '../utils/api';
import { INotification } from '../interfaces/notification.interface';

export const NotificationService = {
  getNotifications: async function (): Promise<INotification[]> {
    const { data } = await Api.get<INotification[]>(`/notifications`);

    return data;
  },
  markNotificationAsRead: async function (
    notificationId: string,
  ): Promise<void> {
    await Api.post(`/notifications/${notificationId}/read`);
  },
};
